import { GetManyResponseBody, Register } from "@clairejs/core";
import { AbstractHttpClient, AbstractStorage, CrudApi } from "@clairejs/client";

import { Principal } from "../../dto/models/principal";
import { Role } from "../../dto/models/role";
import { User } from "../../dto/models/user";
import { RolePolicy } from "../../dto/models/role-policy";
import { ProjectUser } from "../../dto/models/project-user";
import { ProjectEnvironment } from "../../dto/models/project-environment";
import { ProjectContributor } from "../../dto/models/project-contributor";
import { PrincipalRole } from "../../dto/models/principal-role";
import { GetOperatingEnvsQuery, GetOperatingEnvsResponse } from "../../dto/http/project";
import { CreateUserRequestBody, CreateUserResponseBody } from "../../dto/http/membership";
import { CreateRolePolicyResponse } from "../../dto/http/permissions";
import {
    ResetProjectUserTfaRequest,
    ResetProjectUserTfaResponse,
    ToggleProjectUserTfaRequest,
} from "../../dto/http/project-user";

import { UserInfo } from "../auth/store";

@Register()
export class UserApi extends CrudApi<User> {
    constructor(readonly httpClient: AbstractHttpClient, readonly storage: AbstractStorage) {
        super(User, httpClient);
    }

    async createUser(body: CreateUserRequestBody) {
        const result = await this.httpClient.post<CreateUserResponseBody>("/user", body);
        this.httpClient.resetCache("/projectuser");
        return result;
    }

    async updateCurrentUserInfo(userInfo: Partial<UserInfo>) {
        const currentInfo = await this.getCurrentUserInfo();
        await this.storage.setItem("USER_INFO", { ...currentInfo, ...userInfo });
    }

    async saveCurrentUserInfo(userInfo?: UserInfo) {
        if (userInfo) {
            await this.storage.setItem("USER_INFO", userInfo);
        } else {
            await this.storage.deleteItem("USER_INFO");
        }
    }

    async getCurrentUserInfo() {
        return await this.storage.getItem<UserInfo>("USER_INFO");
    }
}

@Register()
export class PrincipalApi extends CrudApi<Principal> {
    constructor(readonly httpClient: AbstractHttpClient) {
        super(Principal, httpClient);
    }
}

@Register()
export class RoleApi extends CrudApi<Role> {
    constructor(readonly httpClient: AbstractHttpClient) {
        super(Role, httpClient);
    }
}

@Register()
export class ProjectUserApi extends CrudApi<ProjectUser> {
    constructor(readonly httpClient: AbstractHttpClient) {
        super(ProjectUser, httpClient);
    }

    async getIdProjectUsers(search: string) {
        const result = await this.httpClient.get<GetManyResponseBody<User>>(
            `/projectuser/idproject?search="${search}"`,
        );
        return result?.records as User[];
    }

    async resetTfa(data: ResetProjectUserTfaRequest) {
        const result = await this.httpClient.post<ResetProjectUserTfaResponse>("/projectuser/tfa/reset", data);

        return result;
    }

    async turnOnTfa(data: ToggleProjectUserTfaRequest) {
        await this.httpClient.post("/projectuser/tfa/on", data);
    }

    async turnOffTfa(data: ToggleProjectUserTfaRequest) {
        await this.httpClient.post("/projectuser/tfa/off", data);
    }
}

@Register()
export class ProjectContributorApi extends CrudApi<ProjectContributor> {
    constructor(readonly httpClient: AbstractHttpClient) {
        super(ProjectContributor, httpClient);
    }

    async getOperatableEnv(query: GetOperatingEnvsQuery) {
        return await this.httpClient.get<GetOperatingEnvsResponse>(
            `/projectcontributor/envs?projectId="${query.projectId}"`,
        );
    }
}

@Register()
export class PrincipalRoleApi extends CrudApi<PrincipalRole> {
    constructor(readonly httpClient: AbstractHttpClient) {
        super(PrincipalRole, httpClient);
    }
}

@Register()
export class RolePolicyApi extends CrudApi<RolePolicy> {
    constructor(readonly httpClient: AbstractHttpClient) {
        super(RolePolicy, httpClient);
    }

    async assignPolicies(roleId: string, policyIds: string[]) {
        return (await this.httpClient.post("/rolepolicy", { roleId, policyIds })) as CreateRolePolicyResponse;
    }
}

@Register()
export class ProjectEnvApi extends CrudApi<ProjectEnvironment> {
    constructor(readonly httpClient: AbstractHttpClient) {
        super(ProjectEnvironment, httpClient);
    }
}
