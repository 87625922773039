import { Outlet } from "@clairejs/react-web";
import styled from "styled-components";

import CustomSuspense from "../../components/custom-suspense";
import SideMenu from "./side-menu";

import "./style.scss";

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
`;

export default function Admin() {
    return (
        <Wrapper>
            <SideMenu />
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <CustomSuspense>
                    <Outlet />
                </CustomSuspense>
            </div>
        </Wrapper>
    );
}
