import { getAPI, mergeInstances } from "@clairejs/client";
import { getCrudActions, ModelStore, StoreAction } from "@clairejs/react";

import { PrincipalRole } from "../../dto/models/principal-role";
import { Project } from "../../dto/models/project";
import { RolePolicy } from "../../dto/models/role-policy";
import { User } from "../../dto/models/user";
import { CreateUserRequestBody } from "../../dto/http/membership";

import {
    CreateProjectBody,
    GetUserOwnedProjectsResponse,
    GetOperatingEnvsQuery,
    GetOperatingEnvsResponse,
} from "../../dto/http/project";

import { ProjectApi } from "../project/api";
import {
    PrincipalApi,
    PrincipalRoleApi,
    ProjectContributorApi,
    ProjectEnvApi,
    ProjectUserApi,
    RoleApi,
    RolePolicyApi,
    UserApi,
} from "./api";

import { UserStore } from "./store";
import { AuthStore } from "../auth/store";

export const userActions = {
    users: {
        ...getCrudActions(UserApi),
        createUser:
            (data: CreateUserRequestBody): StoreAction<UserStore, User | undefined> =>
            async (dispatch, getStore) => {
                const api = getAPI(UserApi);
                const user = await api.createUser(data);

                if (user) {
                    const store = getStore();
                    dispatch({
                        ...store,
                        users: {
                            instances: [...(store?.users.instances || []), { ...user.user, name: data.name }],
                        },
                    });
                }
                return user?.user;
            },
    },
    principal: { ...getCrudActions(PrincipalApi) },
    projectUser: {
        ...getCrudActions(ProjectUserApi),
    },
    contributors: {
        ...getCrudActions(ProjectContributorApi),
        getOperatableEnvIds:
            (query: GetOperatingEnvsQuery): StoreAction<UserStore, GetOperatingEnvsResponse | undefined> =>
            async (dispatch) => {
                const api = getAPI(ProjectContributorApi);
                const result = await api.getOperatableEnv(query);

                if (!result) return;

                dispatch({
                    operatableEnvs: result.envs,
                });
                return result;
            },
    },
    roles: { ...getCrudActions(RoleApi) },
    principalRoles: {
        ...getCrudActions(PrincipalRoleApi),
        createForUser:
            (principalId: string, roleIds: string[]): StoreAction<ModelStore<PrincipalRole>> =>
            async (dispatch, getStore) => {
                const api = getAPI(PrincipalRoleApi);
                const records = roleIds.map((roleId) => ({ principalId, roleId }));

                const result = await api.createMany({ records });

                if (!result) return;

                const instances = getStore()?.instances.filter((i) => i.principalId !== principalId) || [];
                dispatch({
                    instances: mergeInstances(
                        PrincipalRole,
                        instances,
                        result.records.map((r, index) => ({ ...r, ...records[index] })),
                    ),
                });
            },
        merge:
            (principalRole: Partial<PrincipalRole>): StoreAction<ModelStore<PrincipalRole>> =>
            async (dispatch, getStore) => {
                const instances = getStore()?.instances || [];

                const found = instances.find((i) => i.principalId === principalRole.principalId);
                principalRole.id = principalRole.id || found?.id;

                dispatch({
                    instances:
                        principalRole.roleId === null
                            ? instances.filter((pr) => pr.principalId !== principalRole.principalId)
                            : mergeInstances(PrincipalRole, instances, principalRole.id ? [principalRole] : []),
                });
            },
    },
    rolePolicies: {
        ...getCrudActions(RolePolicyApi),
        assignRolePolicies:
            (roleId: string, policyIds: string[]): StoreAction<ModelStore<RolePolicy>> =>
            async (dispatch, getStore) => {
                const api = getAPI(RolePolicyApi);
                const result = await api.assignPolicies(roleId, policyIds);
                let newInstances = getStore()?.instances.filter((rp) => rp.roleId !== roleId) || [];
                newInstances = [...newInstances, ...result.records];
                dispatch({ instances: newInstances });
            },
        removeByPolicyIds:
            (policyIds: string[]): StoreAction<ModelStore<RolePolicy>> =>
            async (dispatch, getStore) => {
                let newInstances = getStore()?.instances.filter((rp) => !policyIds.includes(rp.policyId));
                dispatch({ instances: newInstances });
            },
    },
    projects: {
        ...getCrudActions(ProjectApi),
        fetchUserProjects:
            (): StoreAction<ModelStore<Project>, GetUserOwnedProjectsResponse | undefined> => async (dispatch) => {
                const api = getAPI(ProjectApi);
                const result = await api.getOwnedProjects();
                dispatch({ instances: result?.records });
                return result;
            },

        selectProject:
            (projectId?: string): StoreAction<AuthStore> =>
            async (dispatch) => {
                const userApi = getAPI(UserApi);
                await userApi.updateCurrentUserInfo({ selectedProjectId: projectId });
                await dispatch({ userInfo: { selectedProjectId: projectId || null! } });
            },

        creatProject:
            (body: CreateProjectBody): StoreAction<ModelStore<Project>, void> =>
            async (dispatch, store) => {
                const api = getAPI(ProjectApi);
                const result = await api.createProject(body);

                dispatch({
                    instances: mergeInstances(Project, store()?.instances || [], [
                        {
                            ...result,
                            name: body.name,
                        },
                    ]),
                });
            },

        removeProject:
            (projectId: string): StoreAction<ModelStore<Project>, void> =>
            async (dispatch, store) => {
                const api = getAPI(ProjectApi);
                await api.deleteMany({ fields: { id: [projectId] } });

                dispatch({
                    instances: store()?.instances.filter((i) => i.id !== projectId),
                });
            },
    },
    projectEnvs: {
        ...getCrudActions(ProjectEnvApi),
    },
};
