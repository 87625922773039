import { AbstractModel, Column, FK, Model } from "@clairejs/core";
import { Project } from "./project";

@Model()
export class Role extends AbstractModel {
    @Column({
        description: "Name of role",
        textLength: 255,
        isLabel: true,
    })
    name!: string;

    @Column({
        description: "Id of project this roles belongs",
        isRequired: true,
        ...FK({ model: Project }),
    })
    projectId!: string;

    @Column({
        description: "Whether this role is for system admin",
        isRequired: true,
        serverValue: true,
    })
    isSystemAdmin: boolean = false;

    @Column({
        description: "Whether this role is for unauthenticated users",
        isRequired: true,
        serverValue: true,
    })
    isAnonymousRole: boolean = false;

    @Column({
        description: "Whether this role is ",
        isRequired: true,
        serverValue: true,
    })
    isServiceBasedRole: boolean = false;
}
