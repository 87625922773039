import { AbstractModel, Column, Model, FK } from "@clairejs/core";

import { Organization } from "./organization";

@Model()
export class OrganizationPayment extends AbstractModel {
    @Column({
        description: "Id of the organization that own this payment info",
        isRequired: true,
        ...FK({ model: Organization }),
    })
    organizationId!: string;
}
