import { RouterConfig } from "@clairejs/client";
import { lazy } from "react";
import { importDelay } from "@clairejs/react";

import { AuthGuard } from "./auth-guard";
import { OrgOwnerGuard } from "./org-owner-guard";

import App from "../pages/app";
import LandingPage from "../pages/landing-page";
import LoginPage from "../pages/login";
import NotFoundPage from "../pages/notfound/notfound";
import Home from "../pages/home/index";
import Organization from "../pages/organization/index";
import Admin from "../pages/admin";

const UserManagement = lazy(importDelay(import("../pages/home/user-management")));
const UserList = lazy(importDelay(import("../pages/home/user-management/user-list")));
const UserDetail = lazy(importDelay(import("../pages/home/user-management/user-detail")));
const RoleManagement = lazy(importDelay(import("../pages/home/roles")));
const ServiceManagement = lazy(importDelay(import("../pages/home/service")));
const ServiceList = lazy(importDelay(import("../pages/home/service/service-list")));
const SettingPage = lazy(importDelay(import("../pages/home/settings")));
const PolicyManagement = lazy(importDelay(import("../pages/home/policy")));
const PolicyDetail = lazy(importDelay(import("../pages/home/policy/policy-detail")));
const PolicyList = lazy(importDelay(import("../pages/home/policy/policy-list")));
const RoleList = lazy(importDelay(import("../pages/home/roles/role-list")));
const RoleDetail = lazy(importDelay(import("../pages/home/roles/role-detail")));
const Contributors = lazy(importDelay(import("../pages/home/user-management/contributors")));

const OrgInfo = lazy(importDelay(import("../pages/organization/info/info")));
const Usage = lazy(importDelay(import("../pages/organization/usage")));
const Billing = lazy(importDelay(import("../pages/organization/billing")));
const Method = lazy(importDelay(import("../pages/organization/method")));

const AdminOrgs = lazy(importDelay(import("../pages/admin/orgs")));
const AdminPayments = lazy(importDelay(import("../pages/admin/payments")));
const AdminBankAccounts = lazy(importDelay(import("../pages/admin/bank-accounts")));

const routes: RouterConfig = {
    path: "",
    component: { view: App },
    redirect: "",
    children: [
        { path: "", component: { view: LandingPage } },
        { path: "login", component: { view: LoginPage } },
        {
            path: "admin",
            middleware: [],
            component: { view: Admin },
            redirect: "orgs",
            children: [
                { path: "orgs", component: { view: AdminOrgs } },
                { path: "payments", component: { view: AdminPayments } },
                { path: "accounts", component: { view: AdminBankAccounts } },
            ],
        },
        {
            path: "home",
            middleware: [AuthGuard],
            component: { view: Home },
            children: [
                {
                    path: "user",
                    component: { view: UserManagement },
                    children: [
                        { path: "", component: { view: UserList } },
                        { path: ":id", component: { view: UserDetail } },
                    ],
                },
                {
                    path: "contributors",
                    component: { view: Contributors },
                },
                {
                    path: "role",
                    component: { view: RoleManagement },
                    children: [
                        { path: "", component: { view: RoleList } },
                        { path: ":id", component: { view: RoleDetail } },
                    ],
                },
                {
                    path: "policy",
                    component: { view: PolicyManagement },
                    children: [
                        { path: "", component: { view: PolicyList } },
                        { path: ":id", component: { view: PolicyDetail } },
                    ],
                },
                {
                    path: "service",
                    component: { view: ServiceManagement },
                    children: [{ path: "", component: { view: ServiceList } }],
                },
                { path: "settings", component: { view: SettingPage } },
            ],
        },
        {
            path: "organization",
            middleware: [AuthGuard, OrgOwnerGuard],
            component: { view: Organization },
            redirect: "info",
            children: [
                {
                    path: "info",
                    component: { view: OrgInfo },
                },
                {
                    path: "usage",
                    component: { view: Usage },
                },
                {
                    path: "billing",
                    component: { view: Billing },
                },
                {
                    path: "method",
                    component: { view: Method },
                },
            ],
        },
        { path: "*", component: { view: NotFoundPage } },
    ],
};

export default routes;
