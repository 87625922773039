import { Input, Modal } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { useAPI } from "@clairejs/react";

import { AuthApi } from "../../store/auth/api";

import OtpInput from "../../components/otp-input";

const Wrapper = styled.div`
    .input-group {
        margin-bottom: 8px;
        .input-title {
            margin-bottom: 5px;
        }
    }
`;

interface Props {
    identity?: string;
    onCancel?: () => void;
    onSuccess?: (username: string, password: string) => void;
}
export default function ResetPassword({ identity, onCancel, onSuccess }: Props) {
    const authApi = useAPI(AuthApi);

    const [id, setId] = useState(identity);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [resetSuccess, setResetSuccess] = useState(false);

    const handleOK = async () => {
        if (!id) {
            return;
        }

        if (resetSuccess) {
            onSuccess && onSuccess(id, newPassword);
            return;
        }

        //-- request for password reset
        if (!otpSent) {
            //-- request send otp
            await authApi.resetPasswordOtp({ identity: id });
            setOtpSent(true);
        } else {
            //-- request reset password
            await authApi.resetPasword({
                identity: id,
                otp,
                newPassword,
            });
            setResetSuccess(true);
        }
    };

    const handleCancel = async () => {
        if (resetSuccess || !otpSent) {
            onCancel && onCancel();
        } else {
            //-- go back to edit id
            setOtp("");
            setNewPassword("");
            setOtpSent(false);
        }
    };

    return (
        <Modal
            closable={false}
            open={true}
            onCancel={handleCancel}
            onOk={handleOK}
            okButtonProps={{ disabled: !id }}
            cancelText={resetSuccess ? "Close" : otpSent ? "Change info" : "Cancel"}
            okText={resetSuccess ? "Login and go to console" : otpSent ? "Reset my password" : "Next"}>
            <Wrapper>
                {resetSuccess ? (
                    <div style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                        {"You have successfully reset your password."}
                    </div>
                ) : (
                    <>
                        <div
                            style={{
                                fontWeight: "bold",
                                marginBottom: "21px",
                                textAlign: "center",
                                whiteSpace: "pre-wrap",
                            }}>
                            {"We will help you to recover your password."}
                        </div>
                        <div className="input-group">
                            <div className="input-title">{"Please input your email / phone number below"}</div>
                            <Input
                                placeholder="Your login email / password"
                                disabled={otpSent}
                                defaultValue={id}
                                onChange={(e) => setId(e.target.value)}
                            />
                        </div>
                        {otpSent && (
                            <>
                                <div className="input-group">
                                    <div className="input-title">
                                        {"We sent you an OTP to verify your request, please input here"}
                                    </div>
                                    <OtpInput otpLength={6} onChange={setOtp} />
                                </div>
                                <div className="input-group">
                                    <div className="input-title">{"Please input your new password here"}</div>
                                    <Input.Password
                                        placeholder="Your new password"
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </Wrapper>
        </Modal>
    );
}
