import { useNavigator } from "@clairejs/react-web";
import { Button, Divider, Menu } from "antd";
import { useDispatch, useStore } from "@clairejs/react";
import styled from "styled-components";

import imgLogo from "../../assets/imgs/id.png";

import { AuthStore } from "../../store/auth/store";
import { authActions } from "../../store/auth/actions";
import { useSelectedOrg } from "../../hooks/org";

import { ellipsis } from "../../utils/style";

import Icon from "../../components/icon";

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.1);

    .ant-menu {
        border-inline-end: none !important;
    }

    .ant-menu-item {
        display: flex;
        align-items: center;
    }

    .ant-menu-title-content {
        margin-left: 21px;
    }

    .ant-menu-item:not(.ant-menu-item-selected) {
        color: #666;
    }
`;

interface Props {
    title: string;
    titleExtra?: any;
    links?: boolean;
    items: {
        icon?: string;
        label: any;
        navigate?: string;
    }[];
}
const MenuGroup = (props: Props) => {
    const navigator = useNavigator();

    const path = navigator.getPath();

    const selected = props.items
        .filter((item) => item.navigate && path.endsWith(item.navigate))
        .map((item) => item.label);

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold", fontSize: 13, color: "#333", marginBottom: 8 }}>{props.title}</div>
                <div>{props.titleExtra}</div>
            </div>
            <Menu
                selectedKeys={props.links ? [] : selected}
                items={props.items.map(({ icon, navigate, ...item }) => ({
                    ...item,
                    key: item.label,
                    icon: <Icon size={21} name={icon} />,
                    onClick: () => navigate && navigator.navigate(`/organization/${navigate}`),
                }))}
            />
        </div>
    );
};

export default function SideMenu() {
    const navigator = useNavigator();

    const selectedOrg = useSelectedOrg();

    const orgs = useStore(AuthStore, (s) => s.orgs) || [];
    const userInfo = useStore(AuthStore, (s) => s.userInfo?.userName);

    const dispatch = useDispatch(AuthStore);

    const swapOrg = async () => {
        await dispatch(authActions.switchOrg());
    };

    const logout = async () => {
        await dispatch(authActions.logout());
        navigator.navigate("/");
    };

    return (
        <Wrapper>
            <div
                style={{
                    height: 40,
                    padding: 21,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                <img alt="logo" style={{ height: "100%", aspectRatio: 1, objectFit: "contain" }} src={imgLogo} />
                <div
                    style={{
                        flex: 1,
                        marginLeft: 8,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}>
                    <div
                        style={{
                            ...ellipsis,
                            fontSize: 14,
                            width: 120,
                        }}>
                        {selectedOrg?.name}
                    </div>
                    <div style={{ fontSize: 11, color: "#999" }}>{"Dashboard"}</div>
                </div>
                {orgs.length >= 2 && (
                    <Button onClick={swapOrg} icon={<Icon name="swap-horizontal-outline" size={21} />} />
                )}
            </div>
            <div style={{ padding: 21, flex: 1 }}>
                <MenuGroup
                    title={"MAIN MENU"}
                    items={[
                        {
                            label: "Information",
                            icon: "bar-chart-outline",
                            navigate: "info",
                        },
                        { label: "Billing dashboard", icon: "document-text-outline", navigate: "billing" },
                        { label: "Payment method", icon: "card-outline", navigate: "method" },
                        { label: "Usage", icon: "pie-chart-outline", navigate: "usage" },
                    ]}
                />
                <Divider />
                <MenuGroup
                    links
                    titleExtra={<Icon name="link-outline" size={21} />}
                    title={"LINKS"}
                    items={[
                        {
                            label: (
                                <a href="/home" target="_blank">
                                    {"Projects"}
                                </a>
                            ),
                            icon: "extension-puzzle-outline",
                        },
                        // { label: "Support", icon: "help-buoy-outline" },
                    ]}
                />
            </div>
            <div style={{ padding: 21, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div
                    style={{
                        ...ellipsis,
                        fontSize: 12,
                        color: "#666",
                        width: 144,
                    }}>
                    {userInfo}
                </div>
                <Button icon={<Icon name="log-out-outline" size={21} />} onClick={logout} />
            </div>
        </Wrapper>
    );
}
