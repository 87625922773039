import { useAPI, useDispatch } from "@clairejs/react";
import { NavLink, useNavigator } from "@clairejs/react-web";
import { Button, Card, Divider, Form, Input, Modal } from "antd";
import Title from "antd/lib/typography/Title";
import { useState } from "react";
import { flushSync } from "react-dom";
import styled from "styled-components";

import iconGoogle from "../../assets/imgs/google-icon.png";

import { AuthMethod } from "../../dto/enum/auth-method";
import { AuthProvider } from "../../dto/enum/auth-provider";

import { AuthStore } from "../../store/auth/store";
import { AuthApi } from "../../store/auth/api";
import { UserApi } from "../../store/user/api";

import { Errors } from "../../utils/error-handler";

import FirebaseGoogleLogin from "../../components/firebase-google-login";
import OtpInput from "../../components/otp-input";
import ResetPassword from "./reset-password";
import LinkAccount from "./link-account";

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    background: url("/images/login-bg.jpg");
    background-size: cover;

    .login-dialog {
        padding-left: 89px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .ant-card {
            background: transparent;
            background-color: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(10px);
            border: none;
        }
    }

    .login-title {
        margin-bottom: 13pt;
    }

    .login-form {
        max-width: 377pt;
        min-width: 233px;
        .submit {
            .ant-btn {
                width: 100%;
            }
        }
    }

    .google-login {
        button {
            width: 100%;
        }
    }

    .reset-password {
        display: flex;
        justify-content: center;
        margin-top: 13px;
    }
`;

interface AuthInfo {
    authMethod: AuthMethod;
    authData: string[];
}

export default function LoginPage() {
    const authApi = useAPI(AuthApi);
    const userApi = useAPI(UserApi);

    const dispatchUser = useDispatch(AuthStore, (s) => s.userInfo);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginProgress, setLoginProgress] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [otp, setOtp] = useState("");
    const [showLink, setShowLink] = useState<AuthInfo>();

    const [showReset, setShowReset] = useState(false);

    const navigator = useNavigator();

    const redirect = navigator.getQueries().redirect;

    const login = async (authMethod: AuthMethod, authData: string[]) => {
        try {
            flushSync(() => {
                setLoginProgress(true);
            });

            const loginResult = await authApi.login({ authMethod, authData });

            if (!loginResult) return;

            if (!loginResult.userId) {
                throw Errors.INVALID_ACCOUNT("Cannot use this account");
            }

            if (!loginResult.verified) {
                //--  user is not verified, request otp
                await authApi.requestOtp({ identity: username });
                //-- popup otp dialog
                setShowOtp(true);
            } else {
                //-- persist user info to local storage
                if (loginResult.userId) {
                    await userApi.saveCurrentUserInfo({
                        userId: loginResult.userId,
                        userName: loginResult.userName,
                    });
                    dispatchUser({ userId: loginResult.userId, userName: loginResult.userName });
                }
                //-- set access token
                await authApi.persistAccessToken(loginResult.accessToken!);
                navigator.navigate(redirect || "/home");
            }
        } catch (err: any) {
            if (err.name === "AUTH_NOT_LINKED") {
                setShowLink({ authMethod, authData });
            } else {
                throw err;
            }
        } finally {
            setLoginProgress(false);
        }
    };

    const googleLogin = (idToken: string) => {
        return login(AuthMethod.PROVIDER, [AuthProvider.FIREBASE, idToken]);
    };

    const passwordLogin = (username: string, password: string) => {
        return login(AuthMethod.PASSWORD, [username, password]);
    };

    const verify = async () => {
        await authApi.verifyOtp({ identity: username, otp });
    };

    const verifyAndGo = async () => {
        await verify();
        await passwordLogin(username, password);
    };

    return (
        <Wrapper>
            <div className="login-dialog">
                <Card>
                    <div className="login-form">
                        <Title className="login-title" level={4}>
                            Login
                        </Title>
                        <Form className="login-form" layout="vertical">
                            <Form.Item>
                                <Input
                                    disabled={loginProgress}
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Username"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Input.Password
                                    disabled={loginProgress}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onPressEnter={() => passwordLogin(username, password)}
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <div className="submit">
                                <Button
                                    disabled={!username || !password}
                                    loading={loginProgress}
                                    onClick={() => passwordLogin(username, password)}
                                    type="primary">
                                    Login
                                </Button>
                            </div>
                            <div className="reset-password">
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setShowReset(true);
                                    }}>
                                    Reset my password
                                </Button>
                            </div>
                        </Form>
                        <Divider />
                        <FirebaseGoogleLogin className="google-login" onLogin={googleLogin}>
                            <Button>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img
                                        alt="icon-google"
                                        src={iconGoogle}
                                        style={{
                                            width: "21px",
                                            height: "21px",
                                            objectFit: "contain",
                                            marginRight: "8px",
                                        }}
                                    />
                                    <span>Login using Google</span>
                                </div>
                            </Button>
                        </FirebaseGoogleLogin>
                    </div>
                </Card>
                <NavLink style={{ marginTop: "21px" }} to={"/"}>
                    <Button type="link">Go to home page</Button>
                </NavLink>
            </div>
            {showOtp && (
                <Modal
                    okButtonProps={{ disabled: !otp }}
                    open={true}
                    closable={false}
                    onCancel={() => {
                        setOtp("");
                        setShowOtp(false);
                    }}
                    onOk={verifyAndGo}>
                    <div style={{ marginBottom: "21px", textAlign: "center", whiteSpace: "pre-wrap" }}>
                        {
                            "This account is not verified. We have sent you an OTP to verify it.\nPlease input the OTP to continue."
                        }
                    </div>
                    <OtpInput otpLength={6} onChange={setOtp} />
                </Modal>
            )}

            {showLink && (
                <LinkAccount
                    onCancel={() => setShowLink(undefined)}
                    authMethod={showLink.authMethod}
                    authData={showLink.authData}
                    onSuccess={(newPassword: string) => login(showLink.authMethod, [showLink.authData[0], newPassword])}
                />
            )}

            {showReset && (
                <ResetPassword
                    identity={username}
                    onCancel={() => setShowReset(false)}
                    onSuccess={(username, password) => {
                        passwordLogin(username, password);
                    }}
                />
            )}
        </Wrapper>
    );
}
