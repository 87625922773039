import { Organization } from "../../dto/models/organization";

export interface UserInfo {
    userId: string;
    userName?: string;
    selectedProjectId?: string;
    selectedOrgId?: string;
    lang?: string;
}

export class AuthStore {
    userInfo?: UserInfo;
    orgs?: Organization[];
}
