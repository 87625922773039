import { ApartmentOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useStore } from "@clairejs/react";
import { Outlet, useNavigator } from "@clairejs/react-web";
import { Button, Dropdown, Layout } from "antd";
import { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import styled from "styled-components";

import { authActions } from "../../store/auth/actions";
import { AuthStore } from "../../store/auth/store";
import { userActions } from "../../store/user/actions";
import { UserStore } from "../../store/user/store";
import { SystemStore } from "../../store/system/store";
import { systemActions } from "../../store/system/actions";

import "./index.scss";

import CustomSuspense from "../../components/custom-suspense";
import ProjectSelector from "./project-selector";
import SideMenu from "./side-menu";

const Wrapper = styled.div`
    height: 100%;

    .ant-typography {
        margin: 0 !important;
    }

    .root-layout {
        height: 100%;
        flex-direction: column;
    }

    .logo-holder {
        display: flex;
        align-items: center;
        height: 21pt;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .content-layout {
        flex-direction: row;
    }

    .content-sider {
    }

    .root-content {
        padding: 21pt;
        overflow: auto;
    }

    .user-header {
        display: flex;
        background-color: orange;
        justify-content: space-between;
        align-items: center;
        padding: 3px 34px;
    }

    .project-switch {
        line-height: unset;
        .project-name {
            font-weight: bold;
            font-size: 21px;
        }
    }
`;

export default function AdminHome() {
    const navigator = useNavigator();

    const dispatch = useDispatch(AuthStore);
    const dispatchSelectedProject = useDispatch(AuthStore);
    const dispatchUserStore = useDispatch(UserStore);
    const dispatchUser = useDispatch(UserStore, (s) => s.users);
    const dispatchProjectEnvs = useDispatch(UserStore, (s) => s.projectEnvs);
    const dispatchServiceAccessKey = useDispatch(SystemStore, (s) => s.serviceKeys);

    const currentUserId = useStore(AuthStore, (s) => s.userInfo?.userId);
    const currentUser = useStore(UserStore, (s) => s.users.instances).find((u) => u.id === currentUserId);
    const projects = useStore(UserStore, (s) => s.projects?.instances);
    const projectEnvs = useStore(UserStore, (s) => s.projectEnvs?.instances);
    const selectedProjectId = useStore(AuthStore, (s) => s.userInfo?.selectedProjectId);
    const orgs = useStore(AuthStore, (s) => s.orgs)?.length;

    const selectedEnvId = navigator.getQueries().env_id;

    const [showModal, setShowModal] = useState(false);

    const logout = async () => {
        await dispatch(authActions.logout());
        navigator.navigate("/");
    };

    const handleProjectSelect = async (id: string) => {
        await dispatchSelectedProject(userActions.projects.selectProject(id));
        setShowModal(false);
    };

    useEffect(() => {
        //-- fetch current user
        if (currentUserId) {
            dispatchUser(userActions.users.fetchMany({ fields: { id: [currentUserId] } }));
        }
    }, [currentUserId]);

    useEffect(() => {
        if (selectedProjectId && projects && !projects?.find((p) => p.id === selectedProjectId)) {
            setShowModal(true);
        }
    }, [projects, selectedProjectId]);

    useEffect(() => {
        if (selectedProjectId) {
            //--
            dispatchProjectEnvs(userActions.projectEnvs.fetchMany({ fields: { projectId: [selectedProjectId] } }));
            dispatchUserStore(userActions.contributors.getOperatableEnvIds({ projectId: selectedProjectId })).then(
                (result) => {
                    if (result?.envs.length) {
                        dispatchServiceAccessKey(
                            systemActions.serviceKeys.fetchMany({
                                fields: { projectEnvId: result?.envs.map((env) => env.envId) },
                            }),
                        );
                    }
                },
            );
        }
    }, [selectedProjectId]);

    useEffect(() => {
        if (selectedEnvId && !projects && currentUserId) {
            dispatchProjectEnvs(userActions.projectEnvs.fetchMany({ fields: { id: [selectedEnvId] } }));
        }
    }, [currentUserId, selectedEnvId]);

    useEffect(() => {
        if (!selectedProjectId) {
            const env = projectEnvs?.find((env) => env.id === selectedEnvId);

            if (env) {
                dispatchSelectedProject(userActions.projects.selectProject(env.projectId));
            }
        }
    }, [projectEnvs]);

    const currentProject = projects?.find((p) => p.id === selectedProjectId);

    const menuItems = [
        ...(orgs
            ? [
                  {
                      icon: <ApartmentOutlined />,
                      label: <div>Organization setting</div>,
                      key: "4",
                      onClick: () => navigator.navigate("/organization"),
                  },
              ]
            : []),
        {
            icon: <LogoutOutlined />,
            onClick: logout,
            label: <div>Sign out</div>,
            key: "3",
        },
    ];

    return (
        <Wrapper>
            <Layout className="root-layout">
                <div className="user-header">
                    <div>
                        {currentProject ? (
                            <div className="project-switch">
                                <div className="project-name">{currentProject.name}</div>
                                <div className="d-flex">
                                    <div className="project-id">{`Project ID: ${currentProject.id}`}</div>
                                    <Button onClick={() => setShowModal(true)} type="link">
                                        (switch)
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            "No project selected"
                        )}
                    </div>
                    <div>
                        <Dropdown.Button
                            menu={{
                                items: menuItems,
                            }}
                            placement="bottomRight"
                            trigger={["click"]}
                            icon={<UserOutlined />}>
                            Welcome, {currentUser?.email || currentUser?.phoneNumber || currentUser?.name || "user"}
                        </Dropdown.Button>
                    </div>
                </div>
                <Layout className="content-layout">
                    <Sider width={233} className="content-sider">
                        <SideMenu />
                    </Sider>
                    <Content className="root-content">
                        <CustomSuspense>
                            <Outlet />
                        </CustomSuspense>
                    </Content>
                </Layout>
            </Layout>
            {(projects === undefined || !selectedProjectId || showModal) && (
                <ProjectSelector
                    loading={projects === undefined}
                    projects={projects || []}
                    selectedProjectId={selectedProjectId}
                    onCancel={() => {
                        setShowModal(false);
                    }}
                    onProjectSelect={handleProjectSelect}
                />
            )}
        </Wrapper>
    );
}
