import { Button, Divider, Dropdown, Radio, Space } from "antd";
import { Outlet, useNavigator } from "@clairejs/react-web";
import { useDispatch, useStore } from "@clairejs/react";
import { useEffect, useState } from "react";
import styled from "styled-components";

import "./style.scss";

import { AuthStore } from "../../store/auth/store";
import { authActions } from "../../store/auth/actions";
import { userActions } from "../../store/user/actions";
import { UserStore } from "../../store/user/store";

import { useSelectedOrg } from "../../hooks/org";

import CustomSuspense from "../../components/custom-suspense";
import Icon from "../../components/icon";
import SideMenu from "./side-menu";
import Topbar from "./topbar";

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    height: 100vh;

    .side-menu {
        min-width: 244px;
        max-width: 300px;
    }

    .content-area {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .org-select {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .orgs-list {
            margin-top: 21px;
            margin-bottom: 21px;

            .ant-space-item {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }

        .action {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`;

export default function Organization() {
    const navigator = useNavigator();
    const currentOrg = useSelectedOrg();

    const userInfo = useStore(AuthStore, (s) => s.userInfo);
    const orgs = useStore(AuthStore, (s) => s.orgs) || [];

    const dispatch = useDispatch(AuthStore);
    const dispatchProject = useDispatch(UserStore, (s) => s.projects);

    const [org, setOrg] = useState(userInfo?.selectedOrgId);

    const switchOrg = async () => {
        if (!org) {
            return;
        }

        await dispatch(authActions.switchOrg(org));
    };

    const logout = async () => {
        await dispatch(authActions.logout());
        navigator.navigate("/");
    };

    const fetchOrgProjects = async () => {
        if (!currentOrg?.id) return;

        await dispatchProject(userActions.projects.fetchMany({ fields: { organizationId: [currentOrg.id] } }));
    };

    useEffect(() => {
        //-- fetch org projects
        fetchOrgProjects();
    }, [currentOrg?.id]);

    return (
        <Wrapper>
            <div className="side-menu">
                <SideMenu />
            </div>
            <div className="content-area">
                <CustomSuspense>
                    <Outlet />
                </CustomSuspense>
            </div>
            {!userInfo?.selectedOrgId && (
                <div className="org-select">
                    <div
                        style={{
                            width: 377,
                            height: "100%",
                            backgroundColor: "#599eff",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}>
                        <Icon name="business-outline" size={128} color={"white"} />
                    </div>
                    <div
                        style={{
                            height: "100%",
                            flex: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}>
                        <Topbar>
                            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                <Dropdown
                                    trigger={["click"]}
                                    menu={{
                                        items: [
                                            {
                                                key: "logout",
                                                label: "Logout",
                                                icon: (
                                                    <Icon name="log-out-outline" size={21} style={{ marginRight: 8 }} />
                                                ),
                                                onClick: logout,
                                            },
                                        ],
                                    }}>
                                    <Button type="link">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <span style={{ color: "#333", marginRight: 8 }}>{userInfo?.userName}</span>
                                            <Icon name="chevron-down-outline" size={16} />
                                        </div>
                                    </Button>
                                </Dropdown>
                            </div>
                        </Topbar>
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: "10%",
                            }}>
                            <div style={{ padding: 34, minWidth: "50%" }}>
                                <div style={{ color: "#999" }}>{"Select an organization to continue"}</div>
                                <div className="orgs-list">
                                    <Radio.Group value={org} onChange={(v) => setOrg(v.target.value)}>
                                        <Space direction="vertical">
                                            {orgs.map((org) => (
                                                <Radio key={org.id} value={org.id}>
                                                    {org.name}
                                                </Radio>
                                            ))}
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <Divider />
                                <div className="action">
                                    <div style={{ fontSize: 12, color: "#666", marginRight: 21 }}>
                                        {"Cannot find your organization? Try login with other account."}
                                    </div>
                                    <Button onClick={switchOrg} disabled={!org} type="primary">
                                        {"Next"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Wrapper>
    );
}
