import { ModelStore } from "@clairejs/react";

import { Principal } from "../../dto/models/principal";
import { PrincipalRole } from "../../dto/models/principal-role";
import { Project } from "../../dto/models/project";
import { ProjectContributor } from "../../dto/models/project-contributor";
import { ProjectEnvironment } from "../../dto/models/project-environment";
import { ProjectUser } from "../../dto/models/project-user";
import { Role } from "../../dto/models/role";
import { RolePolicy } from "../../dto/models/role-policy";
import { User } from "../../dto/models/user";

export class UserStore {
    principals: ModelStore<Principal> = { instances: [] };
    users: ModelStore<User> = { instances: [] };
    projectUsers: ModelStore<ProjectUser> = { instances: [] };
    contributors: ModelStore<ProjectContributor> = { instances: [] };
    roles: ModelStore<Role> = { instances: [] };
    principalRoles: ModelStore<PrincipalRole> = { instances: [] };
    rolePolicies: ModelStore<RolePolicy> = { instances: [] };
    projects?: ModelStore<Project>;
    projectEnvs?: ModelStore<ProjectEnvironment>;
    operatableEnvs: { envId: string; allowWrite: boolean }[] = [];
}
