import { Register } from "@clairejs/core";
import { AbstractViewMiddleware, UrlInfo, AbstractTokenManager } from "@clairejs/client";

@Register()
export class AuthGuard extends AbstractViewMiddleware {
    constructor(protected readonly tokenManger: AbstractTokenManager) {
        super();
    }

    async intercept(from: UrlInfo | undefined, to: UrlInfo) {
        const accessToken = await this.tokenManger.getAccessToken();

        if (!accessToken) {
            const rawQuery = Object.entries(from?.query || {})
                .map(([k, v]) => [k, v].join("="))
                .join("&");

            const redirect = from ? `${from.path}?${rawQuery}` : "";
            return { path: `/login?${redirect && `redirect=${redirect}`}` };
        }

        return undefined;
    }
}
