import { UserOutlined } from "@ant-design/icons";
import { useAPI, useDispatch, useStore } from "@clairejs/react";
import { useNavigator, Link } from "@clairejs/react-web";
import { Button, Input, Modal } from "antd";
import { useState } from "react";
import styled from "styled-components";

import imgGoogleIcon from "../assets/imgs/google-icon.png";

import { AuthMethod } from "../dto/enum/auth-method";

import { AuthStore } from "../store/auth/store";
import { AuthApi, RegisterApi } from "../store/auth/api";
import { UserApi } from "../store/user/api";

import OtpInput from "../components/otp-input";
import FirebaseGoogleLogin from "../components/firebase-google-login";
import { CreateSessionResponse } from "../dto/http/session";
import { RegisterUserResponseBody } from "../dto/http/membership";
import { UserStore } from "../store/user/store";

const Wrapper = styled.div`
    background-image: url("/images/back.jpg");
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .name {
        color: white;
        font-size: 16px;
        font-weight: bold;
    }

    .buttons {
        display: flex;
        align-items: center;
        .ant-btn {
            margin-left: 21px;
        }
    }

    .header {
        padding: 21px 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(1px);

        .welcome {
            color: white;
        }
    }

    .main {
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        overflow: hidden;

        .slogan {
            font-size: 34px;
            color: white;
            white-space: pre-wrap;
            padding-top: 5rem;
            padding-left: 4rem;
        }

        .signup-drawer {
            position: absolute;
            right: 0;
            top: 0;
            max-width: 477px;
            display: flex;
            align-items: center;
            padding: 55px;
            right: -610px;
            visibility: hidden;

            .signup-dialog {
                width: 100%;
                max-width: 233px;
                padding: 21px 34px;
                border-radius: 8px;
                background-color: rgba(255, 255, 255, 0.4);
                backdrop-filter: blur(10px);
                display: flex;
                flex-direction: column;

                .signup-group {
                    margin-bottom: 34px;
                }

                .signup-method {
                    font-size: 16px;
                }

                .input-field {
                    margin-top: 13px;
                }

                .notice {
                    margin-top: 8px;
                    font-size: 11px;
                    width: 100%;
                    text-align: center;
                    a {
                        display: inline-block;
                    }
                }
            }

            &.visible {
                visibility: visible;
                transition: 200ms ease-in-out;
                right: 0;
            }
        }
    }

    .foot {
        padding-bottom: 2rem;
        padding-left: 4rem;
        color: white;
    }
`;

export default function LandingPage() {
    const authApi = useAPI(AuthApi);
    const userApi = useAPI(UserApi);
    const registerApi = useAPI(RegisterApi);

    const [showSignup, setShowSignup] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [processing, setProcessing] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [showAlreadyRegisterModal, setShowAlreadyRegisterModal] = useState(false);
    const [otp, setOtp] = useState("");

    const navigator = useNavigator();

    const userInfo = useStore(AuthStore, (s) => s.userInfo);
    const orgs = useStore(AuthStore, (s) => s.orgs);
    const projects = useStore(UserStore, (s) => s.projects?.instances);

    const dispatchUser = useDispatch(AuthStore, (s) => s.userInfo);

    const letUserIn = async (result: CreateSessionResponse | RegisterUserResponseBody) => {
        if (result.userId) {
            await userApi.saveCurrentUserInfo({
                userId: result.userId,
                userName: result.userName,
            });
            dispatchUser({ userId: result.userId, userName: result.userName });
        }
        if (result.accessToken) {
            await authApi.persistAccessToken(result.accessToken);
            navigator.navigate("/home");
        }
    };

    const signup = async (authMethod: AuthMethod, authData: string[]) => {
        try {
            setProcessing(true);
            const registerResult = await registerApi.register({ authMethod, authData });

            if (!registerResult) return;

            if (!registerResult.verified) {
                //-- user is not verified
                setShowOtp(true);
            } else {
                await letUserIn(registerResult);
            }
        } catch (err: any) {
            if (err.name === "ALREADY_REGISTER") {
                setShowAlreadyRegisterModal(true);
            } else {
                throw err;
            }
        } finally {
            setProcessing(false);
        }
    };

    const signUpWithEmail = () => signup(AuthMethod.PASSWORD, [email, password]);
    const signUpWithGoogle = (token: string) => signup(AuthMethod.PROVIDER, ["FIREBASE", token]);

    const verify = async () => {
        try {
            setProcessing(true);
            await authApi.verifyOtp({ identity: email, otp });
            //-- login again
            const loginResult = await authApi.login({ authMethod: AuthMethod.PASSWORD, authData: [email, password] });

            if (!loginResult) return;

            await letUserIn(loginResult);
        } finally {
            setProcessing(false);
        }
    };

    const isOrgOwner = orgs?.some((org) => org.ownerUserId === userInfo?.userId);
    const isAdmin = projects?.some((p) => p.isIdProject);

    return (
        <Wrapper>
            <div className="header">
                <div className="name">Tamidi Credentials Service</div>
                <div className="buttons">
                    {userInfo ? (
                        <div className="welcome">{`Welcome back, ${userInfo.userName || ""}`}</div>
                    ) : (
                        <Button icon={<UserOutlined />} onClick={() => setShowSignup(true)}>
                            Register new account
                        </Button>
                    )}
                    <Link to={"/home"}>
                        <Button type="primary">Project console</Button>
                    </Link>
                    {isOrgOwner && (
                        <Link to={"/organization"}>
                            <Button>Organization settings</Button>
                        </Link>
                    )}
                    {isAdmin && (
                        <Link to={"/admin"}>
                            <Button type="primary" danger>
                                Admin console
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            <div className="main">
                <div className="slogan">{"Manage identities & permissions\nin your microservices\nthe easy way."}</div>
                <div className={`signup-drawer ${showSignup ? "visible" : ""}`}>
                    <div className="signup-dialog">
                        <div style={{ flex: 1 }}>
                            <div className="signup-group">
                                <div className="signup-method">Using password</div>
                                <Input
                                    disabled={processing}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="input-field"
                                    placeholder="Email address / Phone number"
                                />
                                <Input.Password
                                    disabled={processing}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="input-field"
                                    placeholder="Your password"
                                />
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <div></div>
                                    <Button
                                        onClick={signUpWithEmail}
                                        disabled={!email || !password || processing}
                                        className="input-field"
                                        type="primary"
                                        style={{ width: "100%", marginTop: "21px" }}>
                                        Register
                                    </Button>
                                </div>
                            </div>
                            <div className="signup-group">
                                <div className="signup-method">Using Google account</div>
                                <FirebaseGoogleLogin onLogin={(idToken) => signUpWithGoogle(idToken)}>
                                    <Button disabled={processing} style={{ width: "100%", marginTop: "13px" }}>
                                        <div
                                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <img
                                                style={{
                                                    width: "21px",
                                                    height: "21px",
                                                    objectFit: "contain",
                                                    marginRight: "8px",
                                                }}
                                                src={imgGoogleIcon}
                                                alt="google-icon"
                                            />
                                            <span>Signup using Google</span>
                                        </div>
                                    </Button>
                                </FirebaseGoogleLogin>
                            </div>
                        </div>
                        <div className="notice">
                            <span>By registering account and using this service, you agree to the &nbsp;</span>
                            <Link to={"/term"}>terms and conditions</Link>.
                        </div>
                    </div>
                </div>

                {showAlreadyRegisterModal && (
                    <Modal closable={false} open={true} footer={null}>
                        <div>
                            <div style={{ whiteSpace: "pre-wrap", marginBottom: "21px" }}>
                                {"It looks like you already have an account with us.\nDo you want to login instead?"}
                            </div>
                            <div className="buttons">
                                <Button onClick={() => setShowAlreadyRegisterModal(false)}>Cancel</Button>
                                <Button onClick={() => navigator.navigate("/login")} type="primary">
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}

                {showOtp && (
                    <Modal
                        okButtonProps={{ disabled: !otp }}
                        open={true}
                        closable={false}
                        onCancel={() => {
                            setOtp("");
                            setShowOtp(false);
                        }}
                        onOk={verify}>
                        <div style={{ marginBottom: "21px", textAlign: "center", whiteSpace: "pre-wrap" }}>
                            {`We have sent an OTP to ${email}.\nPlease input it to continue.`}
                        </div>
                        <OtpInput otpLength={6} onChange={setOtp} />
                    </Modal>
                )}
            </div>
            <div className="foot">A product of tamidi.com</div>
        </Wrapper>
    );
}
