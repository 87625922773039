import styled from "styled-components";

const Wrapper = styled.div`
    height: 40px;
    padding: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

interface Props {
    children?: any;
}
export default function Topbar(props: Props) {
    return <Wrapper>{props.children}</Wrapper>;
}
