import { Model, Column, AbstractModel, FK } from "@clairejs/core";
import { Project } from "./project";

@Model()
export class Policy extends AbstractModel {
    @Column({
        description: "Name of the policy",
        textLength: 255,
        isRequired: true,
        searchable: {},
    })
    name!: string;

    @Column({
        description: "The project this policy belongs to",
        isRequired: true,
        ...FK({ model: Project, cascade: "delete" }),
    })
    projectId!: string;
}
