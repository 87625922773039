import { AccessToken, Register } from "@clairejs/core";
import { AbstractHttpClient, AbstractTokenManager } from "@clairejs/client";

import {
    IdentityVerificationRequest,
    LinkAuthMethodRequest,
    LinkAuthMethodResponse,
    RegisterUserRequestBody,
    RegisterUserResponseBody,
    ResetAuthOtpRequest,
    ResetAuthRequest,
    VerificationCodeRequest,
} from "../../dto/http/membership";
import { CreateSessionRequest, CreateSessionResponse } from "../../dto/http/session";

import { UserApi } from "../user/api";

@Register()
export class AuthApi {
    constructor(
        private readonly http: AbstractHttpClient,
        private readonly tokenManager: AbstractTokenManager,
        private readonly userApi: UserApi,
    ) {}

    async persistAccessToken(accessToken: AccessToken) {
        await this.tokenManager.setAccessToken(accessToken);
    }

    async login(request: CreateSessionRequest) {
        try {
            return await this.http.post<CreateSessionResponse>("/session", request, {}, { noAuthorization: true });
        } catch (err: any) {
            throw err.response?.data || err;
        }
    }

    async requestOtp(request: VerificationCodeRequest) {
        await this.http.post("/auth/code", request);
    }

    async verifyOtp(request: IdentityVerificationRequest) {
        await this.http.post("/auth/verify", request);
    }

    async resetPasswordOtp(request: ResetAuthOtpRequest) {
        await this.http.post("/auth/reset/otp", request);
    }

    async resetPasword(request: ResetAuthRequest) {
        await this.http.post("/auth/reset/perform", request);
    }

    async linkAuth(request: LinkAuthMethodRequest) {
        return await this.http.post<LinkAuthMethodResponse>("/auth/link", request);
    }

    async logout() {
        await this.userApi.saveCurrentUserInfo(undefined);
        await this.tokenManager.setAccessToken(undefined);
        this.http.resetCache("");
    }
}

@Register()
export class RegisterApi {
    constructor(private readonly http: AbstractHttpClient) {}

    async register(body: RegisterUserRequestBody) {
        try {
            return await this.http.post<RegisterUserResponseBody>("/user/register", body);
        } catch (err: any) {
            throw err.response?.data || err;
        }
    }
}
