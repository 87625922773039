import { RefreshHttpClient } from "@clairejs/client";
import { AccessToken, Errors } from "@clairejs/core";

export class ApiClient extends RefreshHttpClient {
    public sessionExpiredCallback?: () => void;
    public tfaRequiredCallback?: () => Promise<void>;

    protected async errorHandler<T = any>(operation: () => Promise<T>, err: any): Promise<T | undefined> {
        try {
            if (err.name === Errors.ACCESS_DENIED().name && err.message === "No role attached") {
                return await this.refreshToken()
                    .then(operation)
                    .catch((err) => {
                        throw err.response.data || err;
                    });
            }

            return await super.errorHandler(operation, err);
        } catch (e: any) {
            if (e.name === Errors.SESSION_EXPIRED().name && this.sessionExpiredCallback) {
                this.sessionExpiredCallback();
                return undefined;
            }

            if (e.name === Errors.TFA_REQUIRED().name && this.tfaRequiredCallback) {
                return await this.tfaRequiredCallback().then(operation);
            }

            throw e;
        }
    }

    protected async getRefreshedAccessToken(): Promise<AccessToken> {
        const token = await this.tokenManager.getAccessToken();
        if (!token?.refreshToken) {
            throw Errors.INVALID_TOKEN();
        }

        const result = await this.post(
            "/session",
            {
                authMethod: "REFRESH_TOKEN",
                authData: [token.refreshToken],
            },
            {},
            { noAuthorization: true, noErrorHandling: true },
        );

        if (!result?.accessToken) {
            throw Errors.INVALID_TOKEN();
        }

        return result.accessToken;
    }
}
