import { AbstractModel, Column, FK, Model } from "@clairejs/core";
import { ProjectEnvironment } from "./project-environment";
import { User } from "./user";

@Model()
export class ProjectUser extends AbstractModel {
    @Column({
        description: "Id of the user",
        isRequired: true,
        ...FK({ model: User }),
    })
    userId!: string;

    @Column({
        description: "Id of the project environment this user belongs to",
        isRequired: true,
        ...FK({ model: ProjectEnvironment }),
    })
    projectEnvId!: string;

    @Column({
        description: "Name of user",
        isRequired: true,
        searchable: {},
    })
    name!: string;
}
