import { AbstractModel, Column, Model, FK, HttpMethod, Enum, Field, Data, ElementDto } from "@clairejs/core";

import { PaymentChannel } from "../enum/payment-channel";
import { User } from "./user";

@Data()
export class BillingInformation {
    @Field({})
    fullName?: string;

    @Field({})
    company?: string;

    @Field({})
    taxNumber?: string;

    @Field({})
    address?: string;

    @Field({})
    city?: string;

    @Field({})
    state?: string;

    @Field({})
    country!: string;
}

@Model({
    ignoreCrud: [HttpMethod.POST, HttpMethod.DEL],
})
export class Organization extends AbstractModel {
    @Column({
        description: "Name of the organization",
        isRequired: true,
        textLength: 128,
        searchable: {},
    })
    name!: string;

    @Column({
        description: "Id of owner user",
        isRequired: true,
        ...FK({ model: User }),
    })
    ownerUserId!: string;

    @Column({
        description: "Do not have to pay for usage",
        isRequired: true,
    })
    freePayment: boolean = false;

    @Column({
        description: "Whether this org is disabled",
    })
    disabled?: boolean;

    @Column({
        description: "Preferred payment channel to pay for bills",
        ...Enum(PaymentChannel),
    })
    preferredPaymentChannel?: PaymentChannel;

    @Column({
        description: "Sdk request per hour limit",
        isRequired: true,
    })
    requestPerHourLimit!: number;

    @Column({
        ...ElementDto(BillingInformation),
    })
    billingInfo?: BillingInformation;
}
