import { ModelStore } from "@clairejs/react";

import { BillingPayment } from "../../dto/models/billing-payment";
import { UsageReport } from "../../dto/http/organization/usage";
import { BillingInfo } from "../../dto/http/organization/billing";
import { OrganizationInfo } from "../../dto/http/org";

export class OrganizationStore {
    payment?: ModelStore<BillingPayment>;
    usageReport?: ModelStore<UsageReport>;
    billing?: ModelStore<BillingInfo>;
    orgs?: ModelStore<OrganizationInfo>;
}
