import {
    AbstractModel,
    Column,
    FK,
    HttpMethod,
    Model,
} from "@clairejs/core";

import { Principal } from "./principal";
import { Project } from "./project";

@Model({ ignoreCrud: [HttpMethod.POST] })
export class Service extends AbstractModel {

    @Column({
        description: "Principal of the service",
        isRequired: true,
        serverValue: true,
        ...FK({ model: Principal, cascade: "delete" }),
    })
    principalId!: string;

    @Column({
        description: "The project that this service belongs to",
        isRequired: true,
        ...FK({ model: Project, cascade: "delete" }),
    })
    projectId!: string;

    @Column({
        description: "Name of the service",
        isRequired: true,
        isLabel: true,
        searchable: {},
    })
    name!: string;

    @Column({
        description: "Whether this is the id service itself",
        isRequired: true,
        serverValue: true,
    })
    isIdService: boolean = false;

}
