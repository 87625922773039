import { Model, FK, HttpMethod, Column, AbstractModel } from "@clairejs/core";

import { Principal } from "./principal";

/**
 * Name, email & phone number must be unique in each project environment
 */

@Model({ ignoreCrud: [HttpMethod.POST] })
export class User extends AbstractModel {
    @Column({
        description: "Principal id",
        serverValue: true,
        isRequired: true,
        ...FK({ model: Principal, cascade: "delete" }),
    })
    principalId!: string;

    @Column({
        description: "ID name of user",
        serverValue: true,
        textLength: 64,
        searchable: {},
    })
    name?: string;

    @Column({
        description: "ID email of user",
        serverValue: true,
        textLength: 64,
        searchable: {},
    })
    email?: string;

    @Column({
        description: "ID phone number of user",
        serverValue: true,
        textLength: 16,
        searchable: {},
    })
    phoneNumber?: string;

    @Column({
        description: "Hashed password with salt",
        serverValue: true,
        textLength: 256,
    })
    password?: string;

    @Column({
        description: "Whether the email is verified",
        serverValue: true,
    })
    emailVerified?: boolean;

    @Column({
        description: "Whether the phone number is verified",
        serverValue: true,
    })
    phoneVerified?: boolean;

    @Column({
        description: "Email verification code",
        serverValue: true,
    })
    emailVerificationCode?: string;

    @Column({
        description: "Phone verification code",
        serverValue: true,
    })
    phoneVerificationCode?: string;

    @Column({
        description:
            "User had request for password reset. This flag is used to prevent unlimitted calls to reset someone's password without first calling getting verification code.",
        isRequired: true,
        serverValue: true,
    })
    resetingPassword: boolean = false;

    @Column({
        description: "Number of consecutive verification failures",
        isRequired: true,
        serverValue: true,
    })
    failedVerificationAttempts: number = 0;

    @Column({
        description: "Number of verification requests",
        isRequired: true,
        serverValue: true,
    })
    requestedVerificationCount: number = 0;

    @Column({
        description: "MFA Secret for google authenticator",
        serverValue: true,
    })
    mfaSecret?: string;

    @Column({
        description: "Flagging indicate user have tfa",
        serverValue: true,
    })
    useTfa?: boolean;

    isVerified() {
        return (
            !!this.name ||
            (!!this.email && !!this.emailVerified) ||
            (!!this.phoneNumber && !!this.phoneVerified)
        );
    }
}
