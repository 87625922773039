import { getAPI, mergeInstances } from "@clairejs/client";
import { StoreAction } from "@clairejs/react";

import { AuthMethod } from "../../dto/enum/auth-method";
import { Organization } from "../../dto/models/organization";

import { OrganizationApi } from "../organization/api";
import { UserApi } from "../user/api";
import { AuthApi } from "./api";
import { AuthStore } from "./store";

import { Errors } from "../../utils/error-handler";

export const authActions = {
    login:
        (authMethod: AuthMethod, authData: string[]): StoreAction<AuthStore> =>
        async (dispatch) => {
            const authApi = getAPI(AuthApi);
            const userApi = getAPI(UserApi);

            const loginResult = await authApi.login({ authMethod, authData });

            if (!loginResult) return;

            if (!loginResult.userId) {
                throw Errors.INVALID_ACCOUNT("The account is not suitable for login");
            }

            const userInfo = { userId: loginResult.userId, userName: loginResult.userName || "" };
            await userApi.saveCurrentUserInfo(userInfo);
            dispatch({ userInfo });
        },
    logout: (): StoreAction<AuthStore> => async (dispatch) => {
        const authApi = getAPI(AuthApi);

        await authApi.logout();
        dispatch({ userInfo: null! });
    },
    fetchOwnedOrg: (): StoreAction<AuthStore, Organization[]> => async (dispatch) => {
        const api = getAPI(OrganizationApi);
        const orgs = await api.getOwnedOrgs();
        dispatch({
            orgs,
        });
        return orgs;
    },
    switchOrg:
        (orgId?: string): StoreAction<AuthStore, void> =>
        async (dispatch) => {
            const userApi = getAPI(UserApi);

            await userApi.updateCurrentUserInfo({ selectedOrgId: orgId || null! });

            dispatch({ userInfo: { selectedOrgId: orgId || null! } });
        },

    updateOrg:
        (
            orgId: string,
            data: Partial<Pick<Organization, "name" | "preferredPaymentChannel" | "billingInfo">>,
        ): StoreAction<AuthStore> =>
        async (dispatch, store) => {
            const orgs = store()?.orgs || [];

            const orgIndex = orgs.findIndex((o) => o.id === orgId);

            if (orgIndex < 0) {
                return;
            }

            const orgApi = getAPI(OrganizationApi);

            await orgApi.updateMany({ update: data }, { fields: { id: [orgId] } });
            orgApi.httpClient.resetCache("/organization/owned");

            await dispatch({
                orgs: mergeInstances(Organization, orgs, [{ id: orgId, ...data }]),
            });
        },
};
