import styled from "styled-components";

import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

interface Props {
    className?: string;
    children?: any;
    onLogin?: (idToken: string) => void;
}

const Wrapper = styled.div``;

export default function FirebaseGoogleLogin({ className, children, onLogin }: Props) {
    const performLogin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            const result = await signInWithPopup(auth, provider);
            const idToken = await result.user.getIdToken();
            onLogin && onLogin(idToken);
        } catch (err: any) {
            if (err.code === "auth/popup-closed-by-user") {
                //-- user close popup, do nothing
                return;
            }
            throw err;
        }
    };

    return (
        <Wrapper className={`${className}`} onClick={performLogin}>
            {children}
        </Wrapper>
    );
}
