import { AbstractModel, Model, FK, HttpMethod, Column } from "@clairejs/core";
import { Policy } from "./policy";
import { Role } from "./role";

@Model({ ignoreCrud: [HttpMethod.POST, HttpMethod.PUT, HttpMethod.DEL] })
export class RolePolicy extends AbstractModel {
    @Column({
        description: "Id of role",
        isRequired: true,
        ...FK({ model: Role, cascade: "delete" }),
    })
    roleId!: string;

    @Column({
        description: "Id of policy",
        isRequired: true,
        ...FK({
            model: Policy,
            cascade: "delete",
        }),
    })
    policyId!: string;
}
