import { Register, Errors as CoreErrors, _ } from "@clairejs/core";
import { AbstractErrorHandler, Translator } from "@clairejs/client";
import { Tlt, getText } from "@clairejs/react";
import { Modal, message } from "antd";

export const Errors = {
    ...CoreErrors,
    INVALID_ACCOUNT: _("INVALID_ACCOUNT", 422),
};

const showLargePopup = ["DISPOSABLE_EMAIL"];

@Register()
export class ErrorHandler extends AbstractErrorHandler {
    constructor(protected readonly translator: Translator) {
        super();
    }

    handle(e: any, component?: any): void {
        const error = e.reason.response ? e.reason.response.data : e.reason || e;

        if (showLargePopup.includes(error.name)) {
            Modal.error({
                title: getText(<Tlt exp={`error.name.${error.name}`} />),
                content: getText(<Tlt exp={`error.message.${error.name}`} />),
            });
        } else {
            message.error(`${error.name}${error.message ? `: ${error.message}` : ""}`);
        }
    }
}
