import { Input, Modal } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { useAPI } from "@clairejs/react";

import { AuthMethod } from "../../dto/enum/auth-method";
import { AuthApi } from "../../store/auth/api";

import OtpInput from "../../components/otp-input";

const Wrapper = styled.div`
    .input-group {
        margin-bottom: 8px;
        .input-title {
            margin-bottom: 5px;
        }
    }
`;

interface Props {
    onCancel?: () => void;
    onSuccess?: (newPassword: string) => void;
    authMethod: AuthMethod;
    authData: string[];
}
export default function LinkAccount({ onCancel, onSuccess, authMethod, authData }: Props) {
    const authApi = useAPI(AuthApi);

    const [linkSuccess, setLinkSuccess] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [otpSent, setOtpSent] = useState(false);

    const [identity, setIdentity] = useState(authMethod === AuthMethod.PASSWORD ? authData[0] : authData[1]);

    const handleOk = async () => {
        if (linkSuccess) {
            onSuccess?.(newPassword);
            return;
        }

        if (!otpSent) {
            //-- send otp
            const linkResult = await authApi.linkAuth({
                authMethod,
                authData,
            });

            if (!linkResult) return;

            setOtpSent(true);

            if (linkResult.verified) {
                //-- link success, login with this method and continue
                setIdentity(linkResult.identity);
                setLinkSuccess(true);
            }
        } else {
            await authApi.resetPasword({ identity, newPassword, otp });
            setLinkSuccess(true);
        }
    };

    const handleCancel = async () => {
        onCancel && onCancel();
    };

    return (
        <Modal
            open={true}
            closable={false}
            okText={linkSuccess ? "Go to console" : otpSent ? "Link" : "Yes"}
            cancelText={otpSent ? "Cancel" : "No"}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: !newPassword || !otp }}>
            <Wrapper>
                {!otpSent ? (
                    <div style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                        {"We found an account with similar login info.\nDo you want to link with this account?"}
                    </div>
                ) : (
                    <div>
                        <div style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>{`Linking account`}</div>
                        <div style={{ fontSize: "21px", textAlign: "center", marginTop: "13px" }}>{identity}</div>
                        {linkSuccess ? (
                            <div style={{ textAlign: "center", marginTop: "21px" }}>
                                {"We have succesfully link this information to your account."}
                            </div>
                        ) : (
                            <>
                                <div
                                    style={{
                                        marginTop: "21px",
                                        marginBottom: "8px",
                                        textAlign: "center",
                                        whiteSpace: "pre-wrap",
                                    }}>
                                    {"We have sent you an verification code, please input it below"}
                                </div>
                                <OtpInput otpLength={6} onChange={setOtp} />
                                <div className="input-group">
                                    <div className="input-title">{"Please input your new password here"}</div>
                                    <Input.Password
                                        placeholder="Your new password"
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}
            </Wrapper>
        </Modal>
    );
}
