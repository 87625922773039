import { DtoMetadata } from "@clairejs/core";
import { ModelStore } from "@clairejs/react";

import { Policy } from "../../dto/models/policy";
import { PolicyPermission } from "../../dto/models/policy-permission";
import { Service } from "../../dto/models/service";
import { ServiceAccessKey } from "../../dto/models/service-access-key";
import { SystemSetting } from "../../dto/models/system-setting";
import { BankAccount } from "../../dto/models/bank-account";

export class SystemStore {
    systemSettingTemplate?: DtoMetadata;
    systemSettings: ModelStore<SystemSetting> = { instances: [] };
    services: ModelStore<Service> = { instances: [] };
    serviceKeys: ModelStore<ServiceAccessKey> = { instances: [] };
    policies: ModelStore<Policy> = { instances: [] };
    policyPermissions: ModelStore<PolicyPermission> = { instances: [] };
    bankAccounts?: ModelStore<BankAccount>;
}
