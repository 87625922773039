import { DefaultHttpClient, getAPI, mergeInstances } from "@clairejs/client";
import { CreateManyRequestBody, CreateManyResponseBody, DtoMetadata } from "@clairejs/core";
import { getCrudActions, ModelStore, StoreAction } from "@clairejs/react";

import { SystemSetting } from "../../dto/models/system-setting";
import { Service } from "../../dto/models/service";
import { PolicyPermission } from "../../dto/models/policy-permission";
import { ServiceAccessKey } from "../../dto/models/service-access-key";
import {
    CreateServiceRequest,
    CreateServiceResponse,
    UpdateServiceRequest,
    UpdateServiceResponse,
} from "../../dto/http/internal-system";
import { UpdatePolicyPermissionsRequestBody } from "../../dto/http/permissions";

import {
    BankAccountApi,
    PolicyApi,
    PolicyPermissionApi,
    ServiceAccessKeyApi,
    ServiceApi,
    SystemSettingApi,
} from "./api";
import { BankAccount } from "../../dto/models/bank-account";

export const systemActions = {
    getSettingTemplate: (): StoreAction<DtoMetadata | undefined> => async (dispatch) => {
        const api = getAPI(SystemSettingApi);

        const dto = await api.getSettingTemplate();
        dispatch(dto);
    },
    systemSettings: {
        ...getCrudActions(SystemSettingApi),
        createMany:
            (
                body: CreateManyRequestBody<SystemSetting>,
            ): StoreAction<ModelStore<SystemSetting>, CreateManyResponseBody<SystemSetting> | undefined> =>
            async (dispatch, getStore) => {
                const api = getAPI(SystemSettingApi);

                const result = await api.createMany(body);

                if (!result) return;

                const createdKeys = body.records.map((r) => r.key);
                const instances = getStore()?.instances || [];

                dispatch({
                    instances: [
                        ...instances.filter((r) => !createdKeys.includes(r.key)),
                        ...result.records.map((r, index) => ({ ...r, ...body.records[index] })),
                    ],
                });

                return result;
            },
    },
    services: {
        ...getCrudActions(ServiceApi),
        createService:
            (body: CreateServiceRequest): StoreAction<ModelStore<Service>, CreateServiceResponse> =>
            async (dispatch, getStore) => {
                const api = getAPI(ServiceApi);

                const result = await api.createService(body);
                (api.http as DefaultHttpClient).resetCache("/service");
                const instances = getStore()?.instances || [];
                dispatch({
                    instances: instances.concat({
                        name: body.name,
                        projectId: body.projectId,
                        ...result,
                    } as Service),
                });
                return result;
            },
        updateService:
            (serviceId: string, body: UpdateServiceRequest): StoreAction<ModelStore<Service>, UpdateServiceResponse> =>
            async (dispatch, getStore) => {
                const api = getAPI(ServiceApi);
                const result = await api.updateService(serviceId, body);
                dispatch({
                    instances: mergeInstances(api.model, getStore()?.instances || [], [{ id: serviceId, ...body }]),
                });
                return result;
            },
    },
    serviceKeys: {
        ...getCrudActions(ServiceAccessKeyApi),
        renewServiceAccessKey:
            (serviceId: string, envId: string): StoreAction<ModelStore<ServiceAccessKey>, void> =>
            async (dispatch, store) => {
                const api = getAPI(ServiceApi);

                const result = await api.rotateServiceAccessKey({
                    serviceId: serviceId,
                    projectEnvId: envId,
                });

                dispatch({
                    instances: mergeInstances(ServiceAccessKey, store()?.instances || [], [
                        {
                            id: result.accessKeyId,
                            serviceId,
                            projectEnvId: envId,
                            serviceSecret: result.key,
                        },
                    ]),
                });
            },
    },
    policies: { ...getCrudActions(PolicyApi) },
    policyPermissions: {
        ...getCrudActions(PolicyPermissionApi),
        updatePolicyPermissions:
            (body: UpdatePolicyPermissionsRequestBody): StoreAction<ModelStore<PolicyPermission>> =>
            async (dispatch, getStore) => {
                const api = getAPI(PolicyPermissionApi);
                const result = await api.updatePolicyPermissions(body);
                const newInstances =
                    getStore()?.instances.filter(
                        (pp) => pp.policyId !== body.policyId || pp.serviceId !== body.serviceId,
                    ) || [];
                dispatch({ instances: [...newInstances, ...result.policyPermissions] });
            },
    },
};

export const bankAccountActions = {
    ...getCrudActions(BankAccountApi),
    makeDefault:
        (accountId: string): StoreAction<ModelStore<BankAccount>> =>
        async (dispatch, store) => {
            const accounts = store()?.instances || [];
            const accountIndex = accounts?.findIndex((acc) => acc.id === accountId);

            const api = getAPI(BankAccountApi);
            await api.makeDefault(accountId);

            await dispatch({
                instances: [
                    ...accounts.slice(0, accountIndex).map((acc) => ({ ...acc, default: false })),
                    {
                        ...accounts[accountIndex],
                        default: true,
                    },
                    ...accounts.slice(accountIndex + 1).map((acc) => ({ ...acc, default: false })),
                ],
            });
        },
};
