import {
    AbstractHttpClient,
    AbstractStorage,
    AbstractTokenManager,
    DefaultTokenManager,
    Translator,
} from "@clairejs/client";
import {
    AbstractLogger,
    AbstractProvider,
    Configuration,
    ConsoleLogMedium,
    DefaultLogger,
    getServiceProvider,
    Inject,
    LogLevel,
    Provider,
} from "@clairejs/core";
import { ClaireApp, I18n } from "@clairejs/react";
import { LocalStorage, ReactWebRoutes } from "@clairejs/react-web";
import { createRoot } from "react-dom/client";

import { initializeApp } from "firebase/app";

import type { Config } from "./config/config";

import "./utils/error-handler";
import { ApiClient } from "./utils/api-client";

import "./index.scss";
import routes from "./routers";
import { UserStore } from "./store/user/store";
import { AuthStore } from "./store/auth/store";
import { SystemStore } from "./store/system/store";
import { OrganizationStore } from "./store/organization/store";

import { translations } from "./assets/translations";
import { ConfigProvider } from "antd";

const env = process.env.REACT_APP_ENV || "local";

@Provider(Configuration)
export class AppConfigProvider extends AbstractProvider<Configuration> {
    provide() {
        return Object.assign(new Configuration(), require(`./config/${env}`).default);
    }
}

@Provider(AbstractLogger)
export class LoggerProvider extends AbstractProvider<AbstractLogger> {
    provide(): AbstractLogger {
        return new DefaultLogger(LogLevel.DEBUG, [new ConsoleLogMedium()]);
    }
}

@Provider(AbstractHttpClient)
export class HttpClientProvider extends AbstractProvider<AbstractHttpClient> {
    constructor(
        @Inject(Configuration) readonly config: Config,
        readonly logger: AbstractLogger,
        readonly tokenManager: AbstractTokenManager,
    ) {
        super();
    }

    provide(): AbstractHttpClient {
        return new ApiClient(this.config.API_SERVER_URL, this.tokenManager, this.logger);
    }
}

@Provider(AbstractTokenManager)
export class TokenManagerProvider extends AbstractProvider<AbstractTokenManager> {
    constructor(readonly storage: AbstractStorage) {
        super();
    }

    provide(): AbstractTokenManager {
        return new DefaultTokenManager(this.storage);
    }
}

const bootstrap = async () => {
    const serviceProvider = getServiceProvider();
    serviceProvider.register(LocalStorage);
    serviceProvider.register(Translator);

    //-- init translation
    const injector = serviceProvider.getInjector();
    await injector.initInstances();

    //-- init firebase app
    initializeApp({
        apiKey: "AIzaSyDwLDqy9cx8Cf44O3Z0yOcOpePYSJzGOQ8",
        authDomain: "tamidi.firebaseapp.com",
        projectId: "tamidi",
        storageBucket: "tamidi.appspot.com",
        messagingSenderId: "79790268281",
        appId: "1:79790268281:web:eb9001f730c3559ae8d9a8",
        measurementId: "G-CSTFE445KH",
    });
};

const container = document.getElementById("root")!;
createRoot(container).render(
    <ClaireApp bootstrap={bootstrap} stores={[AuthStore, UserStore, SystemStore, OrganizationStore]}>
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: `"Open Sans", sans-serif`,
                },
            }}>
            <I18n translations={translations}>
                <ReactWebRoutes routeConfig={routes} />
            </I18n>
        </ConfigProvider>
    </ClaireApp>,
);
