import { AbstractModel, Column, FK, Model } from "@clairejs/core";
import { ProjectEnvironment } from "./project-environment";

@Model()
export class SystemSetting extends AbstractModel {
    @Column({
        description: "Setting key",
        isRequired: true,
        isSymbol: true,
        textLength: 256,
    })
    key!: string;

    @Column({
        description: "Setting value",
        allowNull: true,
    })
    value?: string;

    @Column({
        description: "Id of the project that has setting",
        isRequired: true,
        ...FK({ model: ProjectEnvironment, cascade: "delete" }),
    })
    projectEnvId!: string;
}
