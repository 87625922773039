import { AbstractModel, Column, Model, FK } from "@clairejs/core";
import { ProjectEnvironment } from "./project-environment";
import { User } from "./user";

@Model()
export class ProjectContributor extends AbstractModel {
    @Column({
        description: "Id of allowed project env",
        isRequired: true,
        ...FK({ model: ProjectEnvironment, cascade: "delete" }),
    })
    projectEnvId!: string;

    @Column({
        description: "Id of allowed user",
        isRequired: true,
        ...FK({ model: User, cascade: "delete" }),
    })
    userId!: string;

    @Column({
        description: "Whether the user can edit settings",
        isRequired: true,
    })
    editable!: boolean;
}
