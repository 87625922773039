import { Register } from "@clairejs/core";
import { AbstractHttpClient, CrudApi, stringifyQueries } from "@clairejs/client";

import { Organization } from "../../dto/models/organization";
import { BillingPayment } from "../../dto/models/billing-payment";
import {
    GetCurrentUsageQueries,
    GetCurrentUsageResponse,
    GetManyUsageReportQueries,
    GetManyUsageReportResponse,
} from "../../dto/http/organization/usage";
import {
    DownloadInvoiceQueries,
    DownloadInvoiceResponse,
    GetManyBillingHistoryQueries,
    GetManyBillingHistoryResponse,
} from "../../dto/http/organization/billing";
import {
    ListPaymentsQueries,
    ListPaymentsResponse,
    PayBillBody,
    PayBillResponse,
    ResolvePaymentBody,
    TransferInfo,
} from "../../dto/http/organization/payment";
import { GetOwnedOrgResponse, ListOrganizationQueries, ListOrganizationResponse } from "../../dto/http/org";

@Register()
export class UsageReportApi {
    constructor(protected readonly http: AbstractHttpClient) {}

    async getMany(queries: GetManyUsageReportQueries) {
        return await this.http.get<GetManyUsageReportResponse>(`/org/usagereport?${stringifyQueries(queries)}`);
    }

    async getCurrentUsage(queries: GetCurrentUsageQueries) {
        return await this.http.get<GetCurrentUsageResponse>(`/org/currentusage?${stringifyQueries(queries)}`);
    }
}

@Register()
export class BillingHistoryApi {
    constructor(protected readonly http: AbstractHttpClient) {}

    async getMany(queries: GetManyBillingHistoryQueries) {
        return await this.http.get<GetManyBillingHistoryResponse>(`/org/billinghistory?${stringifyQueries(queries)}`);
    }

    async donwloadInvoice(queries: DownloadInvoiceQueries) {
        return await this.http.get<DownloadInvoiceResponse>(`/org/billing/invoice?${stringifyQueries(queries)}`);
    }
}

@Register()
export class BillPaymentApi extends CrudApi<BillingPayment> {
    constructor(protected readonly http: AbstractHttpClient) {
        super(BillingPayment, http);
    }

    async payBill(body: PayBillBody) {
        return await this.http.post<PayBillResponse>(`/payment/bill`, body);
    }

    async listPayments(queries: ListPaymentsQueries) {
        return await this.http.get<ListPaymentsResponse>(`/billingpayment/list?${stringifyQueries(queries)}`);
    }

    async getTransferInfo(paymentId: string) {
        return await this.http.get<TransferInfo>(`/billingpayment/${paymentId}/transferinfo`);
    }

    async resolvePayment(body: ResolvePaymentBody) {
        await this.http.put("/billingpayment/resolve", body);
    }
}

@Register()
export class OrganizationApi extends CrudApi<Organization> {
    constructor(readonly httpClient: AbstractHttpClient) {
        super(Organization, httpClient);
    }

    async getOwnedOrgs() {
        const response = await this.httpClient.get<GetOwnedOrgResponse>("/organization/owned");
        return response?.orgs || [];
    }

    async listOrgs(queries: ListOrganizationQueries) {
        return await this.httpClient.get<ListOrganizationResponse>(`/organization/list?${stringifyQueries(queries)}`);
    }
}
