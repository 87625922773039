import { getServiceProvider } from "@clairejs/core";
import { AbstractHttpClient } from "@clairejs/client";
import { useDispatch, useStore, useAPI, useLanguage } from "@clairejs/react";
import { Outlet, useNavigator } from "@clairejs/react-web";
import { Modal } from "antd";
import { useEffect } from "react";

import { ApiClient } from "../utils/api-client";

import { UserApi } from "../store/user/api";
import { AuthStore } from "../store/auth/store";
import { authActions } from "../store/auth/actions";
import { translations } from "../assets/translations";
import { UserStore } from "../store/user/store";
import { userActions } from "../store/user/actions";

const defaultLang = Object.keys(translations)[0];

export default function App() {
    const userApi = useAPI(UserApi);
    const navigator = useNavigator();
    const [_, setLang] = useLanguage();

    const userInfo = useStore(AuthStore, (s) => s.userInfo);

    const dispatch = useDispatch(AuthStore);
    const dispatchProject = useDispatch(UserStore, (s) => s.projects);

    const fetchUserInfo = async () => {
        const userInfo = await userApi.getCurrentUserInfo();
        if (userInfo) {
            dispatch({ userInfo: userInfo });
        }
    };

    const fetchOrgs = async () => {
        if (!userInfo?.userId) {
            return;
        }

        const orgs = await dispatch(authActions.fetchOwnedOrg());
        //-- if there is only one org then select it
        if (orgs.length === 1) {
            await dispatch(authActions.switchOrg(orgs[0].id));
        }
    };

    useEffect(() => {
        //-- fetch relevant projects
        if (userInfo?.userId) {
            dispatchProject(userActions.projects.fetchUserProjects());
        }
    }, [userInfo?.userId]);

    useEffect(() => {
        fetchOrgs();
    }, [userInfo?.userId]);

    useEffect(() => {
        setTimeout(() => setLang(userInfo?.lang || defaultLang));
    }, [userInfo?.lang || defaultLang]);

    useEffect(() => {
        //-- set up session expired handle
        const sp = getServiceProvider();
        const httpClient = sp.getInjector().resolve(AbstractHttpClient);

        (httpClient as ApiClient).sessionExpiredCallback = () => {
            Modal.confirm({
                title: "Phiên làm việc hết hạn",
                content: "Bạn vui lòng đăng nhập lại",
                onOk: () => {
                    dispatch(authActions.logout());
                    navigator.navigate("/");
                },
            });
        };

        fetchUserInfo();
    }, []);

    return <Outlet />;
}
