import { Model, AbstractModel, HttpMethod, Column } from "@clairejs/core";

@Model({ ignoreCrud: [HttpMethod.POST, HttpMethod.PUT, HttpMethod.DEL] })
export class PolicyPermissionCondition extends AbstractModel {
    @Column({
        description: "Policy permission Id",
        isRequired: true,
    })
    policyPermissionId!: string;

    @Column({
        description: "Name of the condition",
        isRequired: true,
        textLength: 255,
    })
    conditionName!: string;

    @Column({
        description: "JSON-encoded condition value",
        isRequired: true,
        textLength: 4095,
    })
    conditionValue!: string;
}
