import { LoadingOutlined } from "@ant-design/icons";
import { Suspense } from "react";

interface Props {
    children?: any;
}
export default function CustomSuspense({ children }: Props) {
    return (
        <Suspense
            fallback={
                <div style={{ display: "flex", justifyContent: "center", padding: 21 }}>
                    <LoadingOutlined />
                </div>
            }>
            {children}
        </Suspense>
    );
}
