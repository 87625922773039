import { AbstractViewMiddleware, UrlInfo } from "@clairejs/client";
import { Register } from "@clairejs/core";

@Register()
export class OrgOwnerGuard extends AbstractViewMiddleware {
    async intercept(from: UrlInfo | undefined, to: UrlInfo) {
        // throw new Error("Method not implemented.");
        return undefined;
    }
}
