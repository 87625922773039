import { CSSProperties } from "react";

interface Props {
    name?: string;
    size?: number;
    color?: string;
    style?: CSSProperties;
}
export default function Icon({ size, color, style, ...props }: Props) {
    return (
        // @ts-ignore
        <ion-icon
            {...props}
            style={{
                ...style,
                fontSize: size,
                color,
                alignSelf: "center",
                justifySelf: "center",
            }}
        />
    );
}
