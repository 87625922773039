import { FK, AbstractModel, Model, Column } from "@clairejs/core";
import { Principal } from "./principal";
import { Role } from "./role";

@Model()
export class PrincipalRole extends AbstractModel {
    @Column({
        description: "Id of principal",
        ...FK({ model: Principal, cascade: "delete" }),
    })
    principalId!: string;

    @Column({
        description: "Id of role",
        ...FK({ model: Role, cascade: "delete" }),
    })
    roleId!: string;
}
