import { AbstractModel, Column, Model, FK } from "@clairejs/core";

import { Project } from "./project";

@Model({})
export class ProjectEnvironment extends AbstractModel {
    @Column({
        description: "The rank to order environment, lower is less significant",
    })
    rank?: number;

    @Column({
        description: "The project id",
        isRequired: true,
        ...FK({ model: Project, cascade: "delete" }),
    })
    projectId!: string;

    @Column({
        description: "Name of the environment",
        isRequired: true,
    })
    name!: string;

    @Column({
        description:
            "Whether this env is production env. Production env is created by default when a project is created.",
        isRequired: true,
        serverValue: true,
    })
    isProduction: boolean = false;

    @Column({
        description: "URL to call when there is changes in RBAC configuration",
    })
    rbacWebHookUrl?: string;

    @Column({
        description: "Assymtric public key of jwt",
    })
    asymmetricJwtPublic?: string;
}
