import { AbstractModel, Model, HttpMethod, Column } from "@clairejs/core";

@Model({
    ignoreCrud: [HttpMethod.POST, HttpMethod.DEL],
})
export class Principal extends AbstractModel {
    @Column({
        description: "Whether this principal is being banned",
    })
    banned: boolean = false;

    @Column({
        description: "Ban this principal until this time, zero for infinitive",
        isTimestamp: true,
    })
    banningUntil?: number;

    @Column({
        description: "Reason for banning user",
        textLength: 256,
    })
    banningReason?: string;

    @Column({
        description: "Number of consecutive failed login attempts",
        isRequired: true,
        serverValue: true, // failed login attempts will be counted by the server
    })
    failedLoginAttempts: number = 0;

    @Column({
        description: "TFA secret to generate tfa code",
        serverValue: true, //tfa secret will be generated and stored in server
    })
    tfaSecret?: string;
}
