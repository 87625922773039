import { AbstractModel, Column, FK, Model } from "@clairejs/core";

import { ProjectEnvironment } from "./project-environment";
import { Service } from "./service";

@Model({
    description: "Each service has separated access key for each environment",
})
export class ServiceAccessKey extends AbstractModel {
    @Column({
        description: "Id of the service owning this key",
        isRequired: true,
        ...FK({ model: Service, cascade: "delete" }),
    })
    serviceId!: string;

    @Column({
        description: "Id of the project environment",
        isRequired: true,
        ...FK({ model: ProjectEnvironment, cascade: "delete" }),
    })
    projectEnvId!: string;

    @Column({
        description: "Auth info for this service in current environment",
        textLength: 64,
        serverValue: true,
    })
    serviceSecret?: string;

    @Column({
        description: "RBAC discovery endpoint",
        textLength: 512,
        allowNull: true,
    })
    rbacDiscoveryUrl?: string;
}
