import { AbstractModel, Column, Enum, FK, Index, Model } from "@clairejs/core";

import { BillingStatus } from "../enum/billing-status";
import { Organization } from "./organization";

@Model()
@Index([["organizationId", 1]])
@Index([["billingStatus", 1]])
@Index(
    [
        ["year", 1],
        ["month", 1],
        ["organizationId", 1],
    ],
    { unique: true },
)
export class BillingHistory extends AbstractModel {
    @Column({
        description: "Full year of bill period",
        isRequired: true,
    })
    year!: number;

    @Column({
        description: "One-based month value of month period",
        isRequired: true,
    })
    month!: number;

    @Column({
        description: "Id of the organization being billed",
        isRequired: true,
        ...FK({ model: Organization }),
    })
    organizationId!: string;

    @Column({
        description: "Amount of billing without VAT",
        isRequired: true,
        rangeProps: { min: 0 },
        floating: true,
    })
    amount!: number;

    @Column({
        description: "VAT amount",
        isRequired: true,
        rangeProps: { min: 0 },
        floating: true,
    })
    vat!: number;

    @Column({
        description: "Total amount of bill",
        isRequired: true,
        rangeProps: { min: 0 },
        floating: true,
    })
    total!: number;

    @Column({
        description: "Currenct of billing",
        isRequired: true,
    })
    currency!: string;

    @Column({
        description: "Billing status",
        isRequired: true,
        ...Enum(BillingStatus),
    })
    billingStatus!: BillingStatus;
}
