import { useNavigator } from "@clairejs/react-web";
import { Button } from "antd";
import Title from "antd/lib/typography/Title";
import styled from "styled-components";

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .sub-title {
        margin-bottom: 13pt;
    }
`;
export default function NotFoundPage() {
    const navigator = useNavigator();
    return (
        <Wrapper>
            <Title level={2}>Not found</Title>
            <div className="sub-title">The page you requested does not exist.</div>
            <Button type="link" onClick={() => navigator.back()}>
                Go back
            </Button>
        </Wrapper>
    );
}
