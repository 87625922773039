import { AbstractModel, Column, Model } from "@clairejs/core";

@Model()
export class BankAccount extends AbstractModel {
    @Column({
        description: "Name of bank",
        isRequired: true,
    })
    bankName!: string;

    @Column({
        description: "Bank account number",
        isRequired: true,
    })
    accountNumber!: string;

    @Column({
        description: "Name of account holder",
        isRequired: true,
    })
    accountHolder!: string;

    @Column({
        description: "Whether this is default bank info to show in user payment",
    })
    default?: boolean;

    @Column({
        description: "Whether this account is disabled and not being used",
    })
    disabled?: boolean;
}
