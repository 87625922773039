import { AppstoreOutlined, SettingOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigator } from "@clairejs/react-web";
import { Menu } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

export default function SideMenu() {
    const navigator = useNavigator();
    const gotoPath = (path: string) => {
        navigator.navigate(path);
    };
    return (
        <Wrapper>
            <Menu
                mode="inline"
                onClick={({ key }) => gotoPath(key)}
                items={[
                    {
                        key: "user-org",
                        icon: <UserOutlined />,
                        label: "Users",
                        children: [
                            { key: "user", label: "Project users" },
                            { key: "contributors", label: "Contributor users" },
                        ],
                    },
                    {
                        key: "service-policy",
                        icon: <AppstoreOutlined />,
                        label: "Services & Policies",
                        children: [
                            {
                                key: "service",
                                label: "Services",
                            },
                            { key: "policy", label: "Policies" },
                        ],
                    },
                    { key: "role", icon: <TeamOutlined />, label: "Roles" },
                    { key: "settings", icon: <SettingOutlined />, label: "Project settings" },
                ]}
            />
        </Wrapper>
    );
}
