import { AbstractHttpClient, CrudApi } from "@clairejs/client";
import { Register } from "@clairejs/core";

import { Project } from "../../dto/models/project";
import { CreateProjectBody, CreateProjectResponse, GetUserOwnedProjectsResponse } from "../../dto/http/project";

@Register()
export class ProjectApi extends CrudApi<Project> {
    constructor(readonly client: AbstractHttpClient) {
        super(Project, client);
    }

    async getOwnedProjects() {
        return await this.client.get<GetUserOwnedProjectsResponse>("/project/relevant");
    }

    async createProject(body: CreateProjectBody) {
        return await this.client.post<CreateProjectResponse>("/project", body);
    }
}
