import { AbstractModel, Column, Enum, FK, HttpMethod, Model } from "@clairejs/core";

import { PaymentChannel } from "../enum/payment-channel";
import { PaymentStatus } from "../enum/payment-status";
import { BillingHistory } from "./billing-history";
import { OrganizationPayment } from "./organization-payment";
import { BankAccount } from "./bank-account";

@Model({
    ignoreCrud: [HttpMethod.DEL, HttpMethod.POST, HttpMethod.PUT],
})
export class BillingPayment extends AbstractModel {
    @Column({
        description: "Id of the bill to charge",
        isRequired: true,
        ...FK({ model: BillingHistory }),
    })
    billId!: string;

    @Column({
        description: "Channel of payment",
        isRequired: true,
        ...Enum(PaymentChannel),
    })
    paymentChannel!: PaymentChannel;

    @Column({
        description: "Reference of payment",
        isRequired: true,
    })
    paymentReference!: string;

    @Column({
        description: "Payment status",
        isRequired: true,
        ...Enum(PaymentStatus),
    })
    paymentStatus!: PaymentStatus;

    @Column({
        description: "Payment error returned by payment service",
    })
    paymentError?: string;

    @Column({
        description: "Currency when perform payment",
        isRequired: true,
    })
    currency!: string;

    @Column({
        description: "Exchange ratio of pair bill-currency/paid-currency when perform payment",
        isRequired: true,
    })
    ratio!: number;

    @Column({
        description: "Total amount of bill in paied currency",
        isRequired: true,
    })
    total!: number;

    @Column({
        description: "Id of payment to cross-check against payment provider",
    })
    providerPaymentReference?: string;

    @Column({
        description: "Id of card that performs the payment",
        ...FK({ model: OrganizationPayment }),
    })
    paymentCard?: string;

    @Column({
        description: "Id of bank account that receives the payment",
        ...FK({ model: BankAccount }),
    })
    paymentBankAccount?: string;
}
