import {
    AbstractModel,
    Model,
    FK,
    HttpMethod,
    Column,
    HasMany,
} from "@clairejs/core";

import { Policy } from "./policy";
import { PolicyPermissionCondition } from "./policy-permission-condition";
import { ProjectEnvironment } from "./project-environment";
import { Service } from "./service";

@Model({
    ignoreCrud: [HttpMethod.POST, HttpMethod.PUT, HttpMethod.DEL],
})
export class PolicyPermission extends AbstractModel {

    @Column({
        description: "Policy of this permission",
        isRequired: true,
        ...FK({ model: Policy, cascade: "delete" }),
    })
    policyId!: string;

    @Column({
        description: "Id of the service this permission belongs to",
        isRequired: true,
        ...FK({ model: Service, cascade: "delete" }),
    })
    serviceId!: string;

    @Column({
        description: "The permission allowed",
        isRequired: true,
        textLength: 255,
    })
    permission!: string;

    @Column({
        description: "Id of the project env",
        isRequired: true,
        ...FK({ model: ProjectEnvironment, cascade: "delete" })
    })
    envId!: string;

    @HasMany({
        model: PolicyPermissionCondition,
        column: "policyPermissionId",
        cascade: "delete",
    })
    conditions!: PolicyPermissionCondition[];

}
