import { green } from "@ant-design/colors";
import { CheckCircleFilled, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "@clairejs/react";
import { Form, Input, Modal } from "antd";
import { useState } from "react";
import styled from "styled-components";

import { Project } from "../../dto/models/project";
import { userActions } from "../../store/user/actions";
import { UserStore } from "../../store/user/store";

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;

    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        .action {
            margin-top: 21px;
            font-size: 16px;
        }
    }

    .projects {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .project-card {
        cursor: pointer;
        background-color: #666;
        border-radius: 8px;
        padding: 21px;
        width: 144px;
        height: 144px;
        margin: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        &.new {
            border: 2px dashed white;
            background-color: transparent;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            .title {
                margin-top: 21px;
            }
        }

        &:hover {
            background-color: #999;
        }

        &.selected {
            background-color: white;
        }

        .project-name {
            font-weight: bold;
            font-size: 16px;
        }
    }

    .buttons {
        margin-top: 34px;
    }
`;

interface CreateProjectData {
    name: string;
}

interface Props {
    loading?: boolean;
    selectedProjectId?: string;
    projects: Project[];
    onProjectSelect?: (projectId: string) => void;
    onCancel?: () => void;
}
export default function ProjectSelector({ loading, projects, selectedProjectId, onProjectSelect, onCancel }: Props) {
    const [createProjectForm] = Form.useForm<CreateProjectData>();
    const [showCreateProject, setShowCreateProject] = useState(false);
    const dispatch = useDispatch(UserStore, (s) => s.projects);

    const selectProject = (projectId: string) => {
        projectId && onProjectSelect && onProjectSelect(projectId);
    };

    const createProject = async () => {
        await dispatch(userActions.projects.creatProject({ name: createProjectForm.getFieldsValue().name }));
        setShowCreateProject(false);
    };

    return (
        <Wrapper>
            {loading ? (
                <div className="loading">
                    <LoadingOutlined style={{ fontSize: 55 }} />
                    <span className="action">{"Loading projects"}</span>
                </div>
            ) : (
                <div className="projects">
                    {projects.map((p) => (
                        <div
                            onClick={() => selectProject(p.id)}
                            className={`project-card ${p.id === selectedProjectId ? "selected" : ""}`}
                            key={p.id}>
                            <div className="project-name">{p.name}</div>
                            {p.id === selectedProjectId && (
                                <CheckCircleFilled style={{ color: green[6], fontSize: 21 }} />
                            )}
                        </div>
                    ))}
                    <div className="project-card new" onClick={() => setShowCreateProject(true)}>
                        <PlusOutlined style={{ fontSize: 55 }} />
                        <span className="title">{"New project"}</span>
                    </div>
                </div>
            )}
            {showCreateProject && (
                <Modal
                    title="Create new project"
                    open={true}
                    onCancel={() => setShowCreateProject(false)}
                    onOk={createProject}>
                    <Form form={createProjectForm}>
                        <Form.Item name="name">
                            <Input placeholder="Project name" />
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </Wrapper>
    );
}
