import { AbstractHttpClient, CrudApi, stringifyQueries } from "@clairejs/client";
import { DtoMetadata, Register } from "@clairejs/core";

import { Policy } from "../../dto/models/policy";
import { PolicyPermission } from "../../dto/models/policy-permission";
import { Service } from "../../dto/models/service";
import { ServiceAccessKey } from "../../dto/models/service-access-key";
import { SystemSetting } from "../../dto/models/system-setting";
import {
    CreateServiceRequest,
    CreateServiceResponse,
    RotateServiceAccessKeyRequest,
    RotateServiceAccessKeyResponse,
    UpdateServiceRequest,
    UpdateServiceResponse,
} from "../../dto/http/internal-system";
import { UpdatePolicyPermissionsRequestBody, UpdatePolicyPermissionsResponseBody } from "../../dto/http/permissions";
import { GetPolicyPermissionInfoQuery, GetPolicyPermissionInfoResponse } from "../../dto/http/policy";
import { BankAccount } from "../../dto/models/bank-account";

@Register()
export class SystemSettingApi extends CrudApi<SystemSetting> {
    constructor(readonly http: AbstractHttpClient) {
        super(SystemSetting, http);
    }

    async getSettingTemplate() {
        const result = await this.http.get("/systemsetting/dto");
        return result as DtoMetadata;
    }

    async flushRbac() {
        await this.http.post("/rbac-cache/reload", {});
    }
}

@Register()
export class ServiceAccessKeyApi extends CrudApi<ServiceAccessKey> {
    constructor(readonly http: AbstractHttpClient) {
        super(ServiceAccessKey, http);
    }
}

@Register()
export class ServiceApi extends CrudApi<Service> {
    constructor(readonly http: AbstractHttpClient) {
        super(Service, http);
    }

    async createService(body: CreateServiceRequest) {
        const result = await this.http.post("/service", body);
        return result as CreateServiceResponse;
    }

    async updateService(id: string, body: UpdateServiceRequest) {
        const result = await this.http.put(`/service/${id}`, body);
        return result as UpdateServiceResponse;
    }

    async rotateServiceAccessKey(body: RotateServiceAccessKeyRequest) {
        const result = await this.http.post(`/service/key-rotation`, body);
        return result as RotateServiceAccessKeyResponse;
    }
}

@Register()
export class PolicyApi extends CrudApi<Policy> {
    constructor(readonly http: AbstractHttpClient) {
        super(Policy, http);
    }

    async getPermissionPolicyInfo(queries: GetPolicyPermissionInfoQuery) {
        const result = await this.http.get(`/policy/permission-info?${stringifyQueries(queries)}`);
        return result as GetPolicyPermissionInfoResponse;
    }
}

@Register()
export class PolicyPermissionApi extends CrudApi<PolicyPermission> {
    constructor(readonly http: AbstractHttpClient) {
        super(PolicyPermission, http);
    }

    async updatePolicyPermissions(body: UpdatePolicyPermissionsRequestBody) {
        const result = await this.httpClient.post("/policypermission", body);
        return result as UpdatePolicyPermissionsResponseBody;
    }
}

@Register()
export class BankAccountApi extends CrudApi<BankAccount> {
    constructor(protected readonly http: AbstractHttpClient) {
        super(BankAccount, http);
    }

    async makeDefault(accountId: string) {
        await this.httpClient.put(`/bankaccount/${accountId}/default`, {});
    }
}
