import {
    AbstractModel,
    Column,
    FK,
    HttpMethod,
    Model,
    Enum,
} from "@clairejs/core";

import { PaymentPlan } from "../enum/payment-plan";
import { Organization } from "./organization";

@Model({
    ignoreCrud: [HttpMethod.POST],
})
export class Project extends AbstractModel {
    @Column({
        description: "Name of the project",
        isRequired: true,
        searchable: {},
    })
    name!: string;

    @Column({
        description: "Id of the organization that own this project",
        isRequired: true,
        ...FK({ model: Organization }),
    })
    organizationId!: string;

    @Column({
        description: "Whether the project is disabled",
        serverValue: true,
    })
    disabled?: boolean;

    @Column({
        description: "Whether this project is the ID project",
        serverValue: true,
    })
    isIdProject: boolean = false;

    @Column({
        description: "Payment plan of this project",
        isRequired: true,
        ...Enum(PaymentPlan),
    })
    paymentPlan: PaymentPlan = PaymentPlan.FREE;
}
